import { FC, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Grid, TextFieldLabel, Typography } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { filesActions, itemsActions, vendorActions } from 'src/ducks/actions'
import { getEmployeeId, getItemById, getJob, getOrderById, getVendor } from 'src/ducks/selectors'
import { FILE_TYPE, ITEM_STATUS, ParseFilesUrl } from 'src/helpers'
import { WorkOrdersContext } from '../../../context'
import { PunchlistFile } from 'src/ducks/types'
import env from '@beam-australia/react-env'
import moment from 'moment'
import FileList from 'src/components/pages/Private/EstimatesView/Tabs/pausedDesigns_Attachments/FileList'

const QCRejectItem: FC = () => {
	const dispatch = useDispatch()
	const FILES_URL = env('FILES_URL') ?? ''
	const userId = useSelector(getEmployeeId)
	const { dispatch: dispatchContext, state: { selectedOrderId, selectedItemId } } = useContext(WorkOrdersContext)
	const { id, orderId } = useParams()
	const order = useSelector(getOrderById(selectedOrderId as string))
	const [followupNotes, setFollowupNotescial] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)
	const item = useSelector(getItemById(selectedItemId as string))
	const vendor = useSelector(getVendor())
	const job = useSelector(getJob())
	const [files, setFiles] = useState<PunchlistFile[]>([])
	const [filesToList, setFilesToList] = useState<any[]>([])
	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		order.vendorId && dispatch(
			vendorActions.fetchVendor(order.vendorId, (_succ: boolean) => {
			})
		)
	}, [dispatch, order.vendorId])

	const handleReject = () => {
		setButtonLoading(true)
		if (!selectedItemId) {
			setButtonLoading(false)
			return
		}
		const formattedFiles = files.map(file => ({
			type: "ITEM_REJECTION_IMAGE",
			fileUrl: file.fileUrl,
			name: file.name,
		}))
		dispatch(itemsActions.rejectItem({
			itemId: selectedItemId as string,
			partialItem: { followupNotes, status: ITEM_STATUS.QC_REJECTED, imageFiles: formattedFiles || [] },
			id: job?.id || ''
		}, (succ) => {
			handleClose()
			if (succ) {
				dispatch(itemsActions.fetchItemsByCategory({
					params: { jobId: id, orderId: orderId || '' }
				}, (_succ) => {
					setButtonLoading(false)
				}))
			}
		}))
	}

	const handleClose = () => {
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
	}

	const handleUpload = (event: any) => {

		if (event.target.files && event.target.files.length > 0) {
			setButtonLoading(true)
			const filesToUpload = event.target.files;
			const filesFormatted = Array.from(filesToUpload).map(file => file as File)
			dispatch(
				filesActions.uploadFiles(filesFormatted, (succ, fileNameList) => {
					// Expecting BE returns files in the same order
					if (succ && fileNameList?.length === event.target.files.length) {
						const filesAdded: PunchlistFile[] = []
						fileNameList?.forEach((name, idx) => {
							const userFileName = filesToUpload[idx]?.name ?? ''
							const fileType = {
								name: userFileName ?? '',
								description: userFileName ?? '',
								fileType: FILE_TYPE.INSPECTION_REPORT,
								fileUrl: ParseFilesUrl({
									baseUrl: FILES_URL,
									avatar: name ?? ''
								}),
								uploadedOn: moment().unix(),
								uploadedBy: userId
							} as PunchlistFile
							filesAdded.push(fileType)
						})
						const newFileDetails = filesAdded;
						setFiles(prevFiles => [...prevFiles, ...newFileDetails])
						const filesFormatted = newFileDetails.map(file => ({
							name: file.name,
							onDeleteClick: () => setFilesToList(prevFiles => prevFiles.filter(f => f.name !== file.name)),
							onViewClick: () => handleViewFile(file),
						}))
						setFilesToList(prevFiles => [...prevFiles, ...filesFormatted])
						setButtonLoading(false)
					}
				}
				)
			)
		}
	}

	// const handleDeleteFile = (file: PunchlistFile) => {
	// 	const request = [{
	// 		op: 'remove' as "remove",
	// 		path: `/imageFiles/${files.indexOf(file)}`
	// 	}]
	// 	dispatch(itemsActions.updateItemByPath({ itemId: selectedItemId, request }, (success) => {
	// 		if (success) {
	// 			setFiles(files.filter(f => f.fileUrl !== file.fileUrl))

	// 		}
	// 	}))
	// }

	const handleViewFile = (file: PunchlistFile) => {
		window.open(file.fileUrl, '_blank')
	}

	return (
		<Grid container spacing={2} padding={2}>

			<Grid container item xs={12}>
				<Grid item xs={12}>
					<Typography variant='h5' align='center'>Provide reason(s) for rejection</Typography>
				</Grid>

			</Grid>
			<Grid item xs={12}>
				<Box>
					<TextFieldLabel
						label=''
						value={followupNotes}
						size='small'
						labelVariant='h6'
						placeholder='Rejecting reason'
						multiline
						rows={6}
						onChange={event => setFollowupNotescial(event.target.value)}
					/>
				</Box>
				<Box>
					<input
						multiple
						accept='image/*'
						onChange={handleUpload}
						type='file'
						ref={inputRef}
						style={{ display: 'none' }}
					/>
					<Box style={{ margin: "16px 0", cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px', width: "100%", padding: "16px", border: "1px dashed var(--border-color)", borderRadius: "8px" }} onClick={() => inputRef.current?.click()}>
						{buttonLoading ? <CircularProgress color='info' size='1.4rem' /> : <>
							<Typography variant='body1Bold'>Upload Rejection Image(s)</Typography>
							<Icon name='Upload' />
						</>
						}
					</Box>
					{files.length > 0 && <FileList files={filesToList} />}
				</Box>
			</Grid>

			<Grid item container xs={12} spacing={2}>
				<Grid item xs={6}>
					<Button variant='outlined' onClick={handleClose} fullWidth>
						Cancel
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant='containedError' disabled={!followupNotes} onClick={handleReject} fullWidth
						startIcon={!buttonLoading && <Icon name='Save' />}
					>
						{buttonLoading ? <CircularProgress color='info' size='1.4rem' /> : 'Save'}
					</Button>
				</Grid>
			</Grid>
		</Grid>

	)
}

export default QCRejectItem