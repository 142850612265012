import { Button, Dialog, Typography } from '@mui/material'
import React from 'react'
import Spinner from 'src/components/pages/Private/PromoCode/SpinnerLoader'

type Props = {
    open: boolean
    onClose: () => void
    onConfirm: () => void
    loading?: boolean
}

const CheckDiscountAlert = ({ open, onClose, onConfirm, loading }: Props) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth PaperProps={{ style: { borderRadius: '16px', padding: '16px' } }}>
            <Typography>
                This estimate has a promo code associated. If you apply the adjustment, the promo code will be removed. Are you sure you want to apply the adjustment?
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button disabled={loading} onClick={onClose} variant="outlined" style={{ marginRight: '16px' }}>Cancel</Button>
                <Button disabled={loading} onClick={onConfirm} variant="contained" color="primary">{loading ? <Spinner color='#fff' size={16} /> : `Apply Adjustment`}</Button>
            </div>
        </Dialog>
    )
}

export default CheckDiscountAlert