import { FC, useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Grid, Typography, TextFieldLabel } from 'src/components/UI'
import { filesActions, itemsActions } from 'src/ducks/actions'
import { WorkOrdersContext } from '../../context'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { FILE_TYPE, ITEM_STATUS, ParseFilesUrl } from 'src/helpers'
import { Item, PunchlistFile } from 'src/ducks/types'
import moment from 'moment'
import env from '@beam-australia/react-env'
import { getEmployeeId } from 'src/ducks/selectors'
import FileList from 'src/components/pages/Private/EstimatesView/Tabs/pausedDesigns_Attachments/FileList'

const Delete: FC<{ many?: boolean, category?: boolean }> = ({ many = false, category = false }) => {
    const { dispatch: dispatchContext, state: { workItems, selectedItemsIds } } = useContext(WorkOrdersContext)
    const dispatch = useDispatch()
    const { id } = useParams()

    const selectedItems = workItems.reduce((acc: any, curr: any) => {
        return (acc = [
            ...acc,
            ...curr.items.filter((item: { itemId: string }) => selectedItemsIds.includes(item.itemId))
        ])
    }, [])

    const itemsTitles = selectedItems.map((item: { title: string }) => { return item.title }) || []
    const countItems = selectedItemsIds?.length
    const removeText = `You’re QC rejecting all the current items. (${countItems}/${countItems})`
    const FILES_URL = env('FILES_URL') ?? ''
    const userId = useSelector(getEmployeeId)
    const [reason, setReason] = useState('')
    const [buttonLoading, setButtonLoading] = useState(false)
    const [files, setFiles] = useState<PunchlistFile[]>([])
    const [filesToList, setFilesToList] = useState<any[]>([])
    const inputRef = useRef<HTMLInputElement>(null)

    const handleClose = () => {
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
    }

    const handleDelete = () => {
        setButtonLoading(true)
        const formattedFiles = files.map(file => ({
            type: "ITEM_REJECTION_IMAGE",
            fileUrl: file.fileUrl,
            name: file.name,
        }))
        selectedItems.forEach((item: Partial<Item>) => {
            dispatch(itemsActions.rejectItem({
                id: id || '',
                itemId: item?.itemId as string,
                partialItem: { status: ITEM_STATUS.QC_REJECTED, followupNotes: reason || "", imageFiles: formattedFiles || [] }
                // partialItem: { ...item, status: ITEM_STATUS.REJECTED, requestedRepairNote: reason }
            }, (succ) => {
                if (succ) {
                    dispatch(itemsActions.fetchItemsByCategory({
                        params: { jobId: id, status: ITEM_STATUS.COMPLETE }
                    }, (succ, items) => {
                        dispatchContext({ type: 'SET_REVIEW_ITEMS', payload: items })
                        dispatchContext({ type: 'REFRESH', payload: true })
                        setButtonLoading(false)
                        if (succ) { handleClose() }
                    }))
                } else {
                    setButtonLoading(false)
                }
            }))
        })


    }


    const handleViewFile = (file: PunchlistFile) => {
        window.open(file.fileUrl, '_blank')
    }

    const handleUpload = (event: any) => {

        if (event.target.files && event.target.files.length > 0) {
            setButtonLoading(true)
            const filesToUpload = event.target.files;
            const filesFormatted = Array.from(filesToUpload).map(file => file as File)
            dispatch(
                filesActions.uploadFiles(filesFormatted, (succ, fileNameList) => {
                    // Expecting BE returns files in the same order
                    if (succ && fileNameList?.length === event.target.files.length) {
                        const filesAdded: PunchlistFile[] = []
                        fileNameList?.forEach((name, idx) => {
                            const userFileName = filesToUpload[idx]?.name ?? ''
                            const fileType = {
                                name: userFileName ?? '',
                                description: userFileName ?? '',
                                fileType: FILE_TYPE.INSPECTION_REPORT,
                                fileUrl: ParseFilesUrl({
                                    baseUrl: FILES_URL,
                                    avatar: name ?? ''
                                }),
                                uploadedOn: moment().unix(),
                                uploadedBy: userId
                            } as PunchlistFile
                            filesAdded.push(fileType)
                        })
                        const newFileDetails = filesAdded;
                        setFiles(prevFiles => [...prevFiles, ...newFileDetails])
                        const filesFormatted = newFileDetails.map(file => ({
                            name: file.name,
                            onDeleteClick: () => setFilesToList(prevFiles => prevFiles.filter(f => f.name !== file.name)),
                            onViewClick: () => handleViewFile(file),
                        }))
                        setFilesToList(prevFiles => [...prevFiles, ...filesFormatted])
                        setButtonLoading(false)
                    }
                }
                )
            )
        }
    }

    return (
        <Grid container spacing={2}>
            {many &&
                <Grid container item xs={12}>
                    {itemsTitles.map((title: string) => {
                        return (
                            <Grid item xs={12}>
                                <Typography>{'• ' + title}</Typography>
                            </Grid>
                        )
                    })}
                </Grid>}

            {many && <Grid item xs={12}> <Typography>{removeText}</Typography></Grid>}

            <Grid item xs={12}>
                <Box>
                    <TextFieldLabel
                        label=''
                        value={reason}
                        size='small'
                        labelVariant='h6'
                        placeholder='Rejecting reason'
                        multiline
                        rows={6}
                        onChange={event => setReason(event.target.value)}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <input
                    multiple
                    accept='image/*'
                    onChange={handleUpload}
                    type='file'
                    ref={inputRef}
                    style={{ display: 'none' }}
                />
                <Box style={{ margin: "16px 0", cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px', width: "100%", padding: "16px", border: "1px dashed var(--border-color)", borderRadius: "8px" }} onClick={() => inputRef.current?.click()}>
                    {buttonLoading ? <CircularProgress color='info' size='1.4rem' /> : <>
                        <Typography variant='body1Bold'>Upload Rejection Image(s)</Typography>
                        <Icon name='Upload' />
                    </>
                    }
                </Box>
                {files.length > 0 && <FileList files={filesToList} />}
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                    <Button variant='outlined' onClick={handleClose} fullWidth>
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant='containedError' disabled={!reason} onClick={handleDelete} fullWidth
                        startIcon={!buttonLoading && <Icon name='Save' />}
                    >
                        {buttonLoading ? <CircularProgress color='info' size='1.4rem' /> : 'Save'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Delete
