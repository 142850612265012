import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/project-coordinator'

const apiController: ApiController = {
  getPortfolioSummary: async ({
    id
  }) => {
    return await axios
      .get(`${base}/portfolio-summaries/${id}`)
      .then(response => response.data)
  },

  getPortfolioCount: async ({
    id,
    workOrderStatus,
    jobStatus,
    fromDate,
    toDate,
    start,
    limit,
    territory,
    territoryManager,
    successManager,
    affiliate,
    affiliateType,
    paymentStatus
  }) => {
    const parameters = formatParams({
      workOrderStatus,
      jobStatus,
      fromDate,
      toDate,
      start,
      limit,
      territory,
      territoryManager,
      successManager,
      affiliateType,
      paymentStatus
    });
    return await axios
      .get(`${base}/portfolio-counts/${id}?${affiliate ? `affiliate=${affiliate}` : ""}&${parameters}`)
      .then(response => response.data)
  },

  getPCWorkOrders: async ({
    id,
    workOrderStatus,
    jobStatus,
    fromDate,
    toDate,
    start,
    limit,
    territory,
    territoryManager,
    successManager,
    affiliate,
    affiliateType,
    paymentStatus
  }) => {
    const parameters = formatParams({
      workOrderStatus,
      jobStatus,
      fromDate,
      toDate,
      start,
      limit,
      territory,
      territoryManager,
      successManager,
      affiliateType,
      paymentStatus
    });
    return await axios
      .get(`${base}/work-orders/${id}?${affiliate ? `affiliate=${affiliate}` : ""}&${parameters}`)
      .then(response => response.data)
  },

  getPCDetails: async ({
    id,
    workOrderStatus,
    jobStatus,
    fromDate,
    toDate,
    start,
    limit,
    territory,
    territoryManager,
    successManager,
    affiliate,
    affiliateType,
    paymentStatus
  }) => {
    const parameters = formatParams({
      workOrderStatus,
      jobStatus,
      fromDate,
      toDate,
      start,
      limit,
      territory,
      territoryManager,
      successManager,
      affiliateType,
      paymentStatus
    });
    return await axios
      .get(`${base}/pc-details/${id}??${affiliate ? `affiliate=${affiliate}` : ""}&${parameters}`)
      .then(response => response.data)
  },

  getPCFinancials: async ({
    id,
    workOrderStatus,
    jobStatus,
    fromDate,
    toDate,
    start,
    limit,
    territory,
    territoryManager,
    successManager,
    affiliate,
    affiliateType,
    paymentStatus
  }) => {
    const parameters = formatParams({
      workOrderStatus,
      jobStatus,
      fromDate,
      toDate,
      start,
      limit,
      territory,
      territoryManager,
      successManager,
      affiliateType,
      paymentStatus
    });
    return await axios
      .get(`${base}/pc-financials/${id}?${affiliate ? `affiliate=${affiliate}` : ""}&${parameters}`)
      .then(response => response.data)
  },

  getPCFinancialsJobs: async ({
    id,
    workOrderStatus,
    jobStatus,
    fromDate,
    toDate,
    start,
    limit,
    territory,
    territoryManager,
    successManager,
    affiliate,
    affiliateType,
    paymentStatus
  }) => {
    const parameters = formatParams({
      workOrderStatus,
      jobStatus,
      fromDate,
      toDate,
      start,
      limit,
      territory,
      territoryManager,
      successManager,
      affiliateType,
      paymentStatus
    });
    return await axios
      .get(`${base}/pc-financials-job/${id}?${affiliate ? `affiliate=${affiliate}` : ""}&${parameters}`)
      .then(response => response.data)
  },
  requestCSV: async (id, searchParams) => {
    const params = formatParams(searchParams)
    return await axios
      .post(`${base}/csv/${id}?${params}&sortBy=createdOn&sortOrder=DESC`)
      .then(response => {
        return response.data
      })
  },

  getCSV: async (csvId) => {
    return await axios
      .get(`${base}/csv/${csvId}`)
      .then(response => {
        return response
      })
  },
}

export default apiController
