import { authTypes } from '../auth/types'
import { PromoCodesActions, promoCodesTypes, PromoCodeType } from './types'

const initialState: PromoCodeType[] = []

const reducer = (state = initialState, action: PromoCodesActions): PromoCodeType[] => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case promoCodesTypes.SET_PROMO_CODES: {
      return payload as PromoCodeType[]
    }

    default:
      return state
  }
}

export default reducer
