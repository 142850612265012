import { Box, Button, Checkbox, Switch, TableContainer, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { promoCodesActions } from 'src/ducks/actions'
import { getPromoCodes } from 'src/ducks/selectors'
import { formatTimestamp } from 'src/helpers'
import styles from './styles.module.scss'
import AntSwitch from 'src/components/UI/CustomUI/atoms/AntSwitch'
import { toast } from 'react-toastify'
import CreatePromoCodeModal from './CreatePromoCodeModal'

type Props = {}

const PromoCode = (props: Props) => {
  const [pageLoading, setPageLoading] = React.useState(false)
  const [createPromoCodeModal, setCreatePromoCodeModal] = React.useState(false)

  const dispatch = useDispatch()

  const promoCodes = useSelector(getPromoCodes())

  const headers: HeaderType[] = [
    {
      id: "code",
      label: "Code",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.code}
          </Typography>
        </Box>
      }
    },
    {
      id: "percentOff",
      label: "Percent Off",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.percentOff}
          </Typography>
        </Box>
      }
    },
    {
      id: "amount",
      label: "Amount",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.amount}
          </Typography>
        </Box>
      }
    },
    {
      id: "createdBy",
      label: "Created By",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.createdBy}
          </Typography>
        </Box>
      }
    },
    {
      id: "createdOn",
      label: "Created On",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.createdOn ? formatTimestamp(row.createdOn, "MM/DD/YYYY hh:mm:ss a") : '-'}
          </Typography>
        </Box>
      }
    },
    {
      id: "lastUpdate",
      label: "Last Update",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.lastUpdate ? formatTimestamp(row.lastUpdate, "MM/DD/YYYY") : '-'}
          </Typography>
        </Box>
      }
    },
    {
      id: "startDate",
      label: "Start Date",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.startDate ? formatTimestamp(row.startDate, "MM/DD/YYYY") : '-'}
          </Typography>
        </Box>
      }
    },
    {
      id: "expirationDate",
      label: "Expiration Date",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.expirationDate ? formatTimestamp(row.expirationDate, "MM/DD/YYYY hh:mm:ss a") : '-'}
          </Typography>
        </Box>
      }
    },
    {
      id: "title",
      label: "Title",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.title}
          </Typography>
        </Box>
      }
    },
    {
      id: "promotionType",
      label: "Promotion Type",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.promotionType}
          </Typography>
        </Box>
      }
    },
    {
      id: "resourceType",
      label: "Resource Type",
      align: "left",
      custom: true,
      Element: (row: any): JSX.Element => {
        return <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Promos__companyName}>
            {row.resourceType}
          </Typography>
        </Box>
      }
    },
    {
      id: "active",
      label: "Active",
      align: "left",
      custom: true,
      Element: (row: any, index): JSX.Element => {
        return <Box key={index} style={{ padding: '1px 10px' }}>
          <AntSwitch key={index} onChange={(value) => {
            handleEditPromoCode(row.id, value.target.checked)
          }}
            checked={row.active}
          />
        </Box>
      }
    }
  ]

  const handleEditPromoCode = (promoCodeId: any, value: boolean) => {
    dispatch(promoCodesActions.updatePromoCode({ id: promoCodeId, active: value }, (_succ: boolean) => {
      if (_succ) {
        fetchPromoCodes()
        toast.success('Promo code updated successfully')
      } else {
        toast.error('Failed to update promo code')
      }
    }))
  }

  const fetchPromoCodes = () => {
    setPageLoading(true)
    dispatch(promoCodesActions.getPromoCodes({}, (_succ: boolean) => {
      setPageLoading(false)
    }))
  }

  useEffect(() => {
    fetchPromoCodes()
  }, [])


  const handleCreatePromoCode = () => {
    setCreatePromoCodeModal(true)
  }

  return pageLoading ? (
    <Box></Box>
  ) : <Box>
    <Box className={styles.Promos__header}>
      <Typography variant='h4'>Promo Codes</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleCreatePromoCode()
        }}
      >
        Create Promo Code
      </Button>
    </Box>
    <TableContainer className={styles.TableScrollBar} style={{ maxHeight: "75vh" }}>
      <Table
        headers={headers}
        defaultOrder="desc"
        sortable
        totalItems={(promoCodes?.length || 0)}
        // callToApi={(start, limit) => {
        //   fetchWorkOrders(start, limit)
        // }}
        className={styles.workOrdersTable}
        hasScroll
        highlightedRows
        hasPagination={false}
        loading={pageLoading}
        stickyHeader
        emptyState={<Box>
          <Typography variant="h6" color="textSecondary">No promo codes found</Typography>
        </Box>}
      >
        {promoCodes || []}
      </Table>
    </TableContainer>
    <CreatePromoCodeModal open={createPromoCodeModal} onClose={() => setCreatePromoCodeModal(false)} />
  </Box>
}

export default PromoCode