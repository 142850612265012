/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { colors, Box, Button, SelectInput, FieldsFilter, Typography, Grid, TextField, IconButton, ButtonBase } from '../../../UI'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { PrivateLoader } from 'src/components/templates'
import { getTerritoriesSelector, getEstimatesForTable, getEstimatesBoxes, getTotalEstimates, getEmployeesForDropdown, getEmployeesForSelect, getEmployees } from 'src/ducks/selectors'
import { getAllCompaniesForMultipleFieldWithoutAllOption, getCompanies } from 'src/ducks/companies/selectors'
import { territoriesActions, companiesActions, territoryActions, estimatesActions, employeesActions, filtersActions } from 'src/ducks/actions'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { Range } from 'src/components/UI/CustomUI/atoms/DatePicker/types'
import { useNavigate } from 'react-router-dom'
import { EMPLOYEE_ROLE_TYPE, ROWS_PER_PAGE_EXTENDED, ESTIMATE_STATUS_FILTERS } from 'src/helpers'
import headers from './headers'
import { icons } from '../../../../assets'
import useStyles from './styles'
import { SearchParams } from 'src/ducks/searches/types'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import TablePagination from 'src/components/UI/CustomUI/organisms/Table/TablePagination'
import moment from 'moment'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useIsXlScreen } from 'src/hooks'
import CreateEstimateModal from './CreateEstimateModal'
import IconSwitch from 'src/components/UI/CustomUI/molecules/IconSwitch'
import TableChartIcon from '@mui/icons-material/TableChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ColumnBoard from './ColumnBoard'
import RadioGroup from 'src/components/UI/CustomUI/atoms/RadioGroup'
import { getEstimatesFilter } from 'src/ducks/filters/selector'
import { EstimatesFilterType } from 'src/ducks/filters/types'
import { debounce } from 'lodash'

const ordersOtions = [
  { key: 'all', label: 'All Orders' },
  { key: 'broker', label: 'Broker Orders' },
  { key: 'institutional', label: 'Institutional Orders' }
]
/*  */
const statusOptions = [
  ESTIMATE_STATUS_FILTERS.ALL,
  ESTIMATE_STATUS_FILTERS.NEW,
  ESTIMATE_STATUS_FILTERS.COMPOSING,
  ESTIMATE_STATUS_FILTERS.IN_REVIEW,
  ESTIMATE_STATUS_FILTERS.RELEASED,
  ESTIMATE_STATUS_FILTERS.EXPIRED,
  ESTIMATE_STATUS_FILTERS.DECLINED,
  ESTIMATE_STATUS_FILTERS.NOT_SUBMITTED,
  ESTIMATE_STATUS_FILTERS.PENDING_APPROVALS,
  ESTIMATE_STATUS_FILTERS.APPROVED,
  ESTIMATE_STATUS_FILTERS.OCR_REVIEW,
  ESTIMATE_STATUS_FILTERS.OCR_PROCESSING,
  ESTIMATE_STATUS_FILTERS.PROCESSING_ACH_PAYMENT,
]

const inUseFilterOptions = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'In-Use',
    value: 'true'
  },
  {
    label: 'Non In-Use',
    value: 'false'
  },
]

const assignFilterOptions = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Assigned',
    value: 'true'
  },
  {
    label: 'Non Assigned',
    value: 'false'
  },
]

const dueDateFilterOptions = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Due Today',
    value: 'dueToday'
  },
]

const Estimates: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [xlScreen] = useIsXlScreen()
  const minWidthSel = xlScreen ? '156px' : '120px'

  interface SearchInputs {
    range: Range
  }

  const estimatesFilters = useSelector(getEstimatesFilter())
  const affiliatesStages = useSelector(getAllCompaniesForMultipleFieldWithoutAllOption)
  const affiliates = useSelector(getCompanies)
  const territoriesOtions = useSelector(getTerritoriesSelector)
  const estimates = useSelector(getEstimatesForTable())
  const estimatorOptions = useSelector(getEmployeesForSelect('All Estimators'))
  const totalEstimates = useSelector(getTotalEstimates)

  const tableHeaders = headers

  const initStatusIndex = useMemo(() => statusOptions.findIndex((option) => option === estimatesFilters.status), [estimatesFilters])

  const [tableLoading, setTableLoading] = useState(true)
  const [pageLoading, setPageLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [reset, setReset] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const [filters, setFilters] = useState(false)
  const [statusSelected, setStatusSelected] = useState<number>(initStatusIndex === -1 ? 0 : initStatusIndex)

  const { status, search, territory, assignedOption, dueDateOption, estimateNumber, estimator, inUseOption, isColumnView, selectedAffiliates } = estimatesFilters

  /* const [loadingCSV, setLoadingCSV] = useState(false)
  const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null); */

  // useEffect(() => {
  //   if (!isColumnView) {
  //     updateEstimatesFilter({ isColumnView: true })
  //   }
  // }, [])

  useEffect(() => {
    if (affiliatesStages.length && !selectedAffiliates && !filters) {
      updateEstimatesFilter({ selectedAffiliates: affiliatesStages })
    };
  }, [affiliatesStages])

  useEffect(() => {
    setStatusSelected(initStatusIndex === -1 ? 0 : initStatusIndex)
  }, [estimatesFilters])

  const getParams = useCallback(() => {
    const searchParams = {} as Partial<SearchParams>

    if (search) { searchParams.search = search }

    if (estimateNumber) { searchParams.publicId = estimateNumber }

    if (territory && territory?.label !== 'All Territories') { searchParams.territory = territory.key }

    if (estimator && estimator.label !== 'All Estimators') { searchParams.estimatorId = estimator.id }

    const resAffiliates = selectedAffiliates?.filter(aff => !aff.hide).reduce((acc: any, curr: any) => acc.concat(curr.key), [])
    const queryAffiliates = resAffiliates?.join(',')
    if (resAffiliates?.length !== 0 && resAffiliates && !resAffiliates?.includes('all')) { searchParams.affiliate = queryAffiliates }

    if (inUseOption?.length && inUseOption !== 'all') searchParams.currentlyInUse = inUseOption

    if (assignedOption?.length && assignedOption !== 'all') searchParams.hasEstimatorId = assignedOption

    if (dueDateOption?.length && dueDateOption !== 'all') searchParams.dueDate = `${Math.floor(new Date().getTime() / 1000)}`

    if (statusSelected !== 0) {
      const keyStatusSelected = Object.keys(ESTIMATE_STATUS_FILTERS)[statusSelected];
      searchParams.status = keyStatusSelected
    }

    return searchParams
  }, [status, search, territory, selectedAffiliates, estimator, inUseOption, assignedOption, dueDateOption, estimateNumber])

  const fetchServices = useCallback(() => {
    setTableLoading(true)
    dispatch(territoriesActions.fetchTerritories((_succ: boolean) => { setPageLoading(false) }))
    dispatch(territoryActions.fetchTerritoryManagers(`${EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER}`, (_succ: boolean) => { setPageLoading(false) }))
    dispatch(companiesActions.fetchCompanies({}, (_succ: boolean) => { setPageLoading(false) }))
    dispatch(employeesActions.fetchEmployees({ page: 0, size: 150, searchParams: { role: EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR, approved: true } }, (_succ: boolean) => { setPageLoading(false) }))
    //dispatch(estimatesActions.fetchEstimatesCounts({ searchParams: {} }, (_succ: boolean) => { setPageLoading(false) }))
  }, [])

  const fetchEstimates = (params: Partial<SearchParams>, page?: number, size?: number) => {
    // if (isColumnView) return;

    setTableLoading(true)
    const searchParams = params
    dispatch(
      estimatesActions.fetchEstimates({ page, size, searchParams }, (_succ: boolean) => {
        setTableLoading(false)
      })
    )

    //setCountsLoading(true)
    /*  dispatch(estimatesActions.fetchEstimatesCounts({ searchParams }, (_succ: boolean) => {
       setCountsLoading(false)
     })) */
  }

  const debouncedFetchEstimates = useCallback(
    debounce(fetchEstimates, 500),
    []
  );

  useEffect(() => {
    if (filters) {
      debouncedFetchEstimates(getParams());
    }
  }, [status, search, territory, selectedAffiliates, estimator, inUseOption, assignedOption, dueDateOption, estimateNumber])

  useEffect(() => {
    if (!isColumnView) debouncedFetchEstimates(getParams())
  }, [isColumnView])

  useEffect(() => {
    if (refresh) {
      fetchServices()
      debouncedFetchEstimates(getParams())

      // refresh for the kanban board
      if (isColumnView) {
        updateEstimatesFilter({ ...estimatesFilters })
      }
    }
    setRefresh(false)
  }, [refresh])

  const handleChangeEstimator = (newSelected: SelectInputOption) => {
    if (!newSelected.key) {
      updateEstimatesFilter({ estimator: estimatorOptions[0] })
    } else {
      updateEstimatesFilter({ estimator: newSelected })
    }
    setFilters(true)
  }

  const handleATerritoryChange = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      updateEstimatesFilter({ territory: territoriesOtions[0] })
    } else {
      updateEstimatesFilter({ territory: newSelected })
    }
    setFilters(true)
  }

  const handleAffiliatesChange = (index: number, id: string): void => {
    setFilters(true)
    if (index === -1) { // unselect all if index is - 1
      updateEstimatesFilter({ selectedAffiliates: selectedAffiliates?.map((aff) => ({ ...aff, hide: true })) })
    } else {
      const realIndex = selectedAffiliates?.findIndex((aff) => aff.id === id)

      updateEstimatesFilter({
        selectedAffiliates: selectedAffiliates?.map((aff, i) => {
          if (i === realIndex)
            aff.hide = !aff.hide
          return aff
        })
      })
    }
  }

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const pattern = /^[a-zA-Z0-9 ,.\-]+$/;
    const notSpecialCharacters = pattern.test(e.target.value);
    if (!notSpecialCharacters && e.target.value) return;
    updateEstimatesFilter({ search: e.target.value })
    setFilters(true)
  }

  const handleChangeEstimateNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length <= 15) {
      updateEstimatesFilter({ estimateNumber: onlyNums })
      setFilters(true)
    }
  }

  const onResetSearchInput = (): void => {
    setReset(true)
    setFilters(false)

    setStatusSelected(0)

    updateEstimatesFilter({
      selectedAffiliates: affiliatesStages,
      estimateNumber: '',
      search: '',
      territory: territoriesOtions[0],
      estimator: estimatorOptions[0],
      inUseOption: inUseFilterOptions[0].value,
      assignedOption: assignFilterOptions[0].value,
      dueDateOption: dueDateFilterOptions[0].value,
      status: statusOptions[0],
    })

    setFilters(true)
  }

  const onSearch = (): void => {
    fetchEstimates(getParams(), 0, 50)
  }

  const handleClickRow = (row: any, e: React.MouseEvent) => {
    if (e.type !== 'contextmenu') {
      navigate(`${row.id}`)
      e.preventDefault()
    }
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const handleInUseOptionChange = (newValue: string) => {
    updateEstimatesFilter({ inUseOption: newValue })

    setFilters(true)
  }

  const handleAssignedOptionChange = (newValue: string) => {
    updateEstimatesFilter({ assignedOption: newValue })

    setFilters(true)
  }

  const handleDueTodayOptionChange = (newValue: string) => {
    updateEstimatesFilter({ dueDateOption: newValue })

    setFilters(true)
  }

  const handleStatusChange = (newStatus: SelectInputOption) => {
    const index = parseInt(newStatus.key)
    setStatusSelected(index || 0)
    updateEstimatesFilter({ status: statusOptions[index] || '' })

    setFilters(true)
  }

  const updateEstimatesFilter = (newFilters: Partial<EstimatesFilterType>) => {
    dispatch(
      filtersActions.setFilters({
        estimates: {
          ...estimatesFilters,
          ...newFilters
        }
      })
    )
  }

  /*   const downloadCSV = (event: any) => {
      const searchParams = getParams();
      setLoadingCSV(true)
      dispatch(jobsActions.downloadCSV({
        searchParams
      }, (succ: boolean) => {
        setLoadingCSV(false)
        downloadRef?.current?.link?.click()
      }))
    } */

  return (
    <div onKeyDown={handleKeyDown}>
      <PrivateLoader loading={pageLoading}>
        <Box display='flex' flexDirection='column' gap={2} className={classes.container}>
          <Grid container spacing={2} className={classes.filters}>
            <Grid item xs={2}>
              <SelectInput
                label='Territory:'
                onChange={handleATerritoryChange}
                options={territoriesOtions}
                value={estimatesFilters.territory || territoriesOtions[0]}
                sx={{ minWidth: minWidthSel, flex: '1' }}
              />
            </Grid>

            <Grid item xs={2}>
              <SelectInput
                label='Status:'
                onChange={handleStatusChange}
                options={statusOptions.map((state, idx) => {
                  return {
                    key: '' + idx,
                    label: state
                  }
                })}
                value={statusOptions[statusSelected]}
                sx={{ minWidth: minWidthSel, flex: '1' }}
              />
            </Grid>

            <Grid item xs={2}>
              <FieldsFilter
                flexColumn
                handleChange={handleAffiliatesChange}
                inputText='All Affiliates:'
                popupLabel=''
                options={estimatesFilters.selectedAffiliates || affiliatesStages}
                renderAsInput
                contentAlign='left'
                label='Affiliate:'
                variant='body1'
                sx={{ minWidth: minWidthSel, flex: '1' }}
                searchable={true}
                popoverSx={{ width: '378px !important' }}
                searchFieldPlaceholder='Enter Affiliate name'
                allSelection={false}
              />
            </Grid>

            <Grid item xs={2}>
              <SelectInput
                label='Estimator:'
                onChange={handleChangeEstimator}
                options={estimatorOptions}
                value={estimatesFilters.estimator || estimatorOptions[0]}
                sx={{ minWidth: minWidthSel, flex: '1' }}
              />
            </Grid>

            <Grid item xs={2}>
              <Box display='flex' flexDirection='column' justifyContent='flex-start' minWidth={xlScreen ? '230px' : '170px'} sx={{ flex: '1' }}>
                <Typography variant='h6'> Search by Address: </Typography>
                <TextField size='small' style={xlScreen ? { height: '30px' } : { height: '30px', minWidth: '50%' }} placeholder='Search By Address' value={estimatesFilters.search || ''} onChange={handleChangeSearchInput} />
              </Box>
            </Grid>
            <Grid item container flexDirection='column' justifyContent='flex-end' width='fit-content'>
              <IconSwitch
                firstIcon={<TableChartIcon style={{ width: '16px', height: '16px' }} />}
                secondIcon={<ListAltIcon style={{ width: '16px', height: '16px' }} />}
                status={!!isColumnView}
                handleToggle={(toggleState: boolean) => {
                  updateEstimatesFilter({ isColumnView: toggleState })
                }
                }
              />
            </Grid>
            <Grid item container flexDirection='column' justifyContent='flex-end' width='fit-content' flex={1}>
              <Button
                sx={{ ml: 0.2, height: '36px' }}
                startIcon={< icons.Add />}
                variant='contained'
                onClick={() => setOpen(true)}
                fullWidth
              >
                New Estimate
              </Button>
            </Grid>
          </Grid>

          {/*  <StatusBoxes data={data} sx={{ textAlign: 'left', alignItems: 'left' }} loading={countsLoading} /> */}
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant='h6'> In-Use Filter: </Typography>
              <RadioGroup
                options={inUseFilterOptions}
                onChange={(e, newValue) => handleInUseOptionChange(newValue)}
                value={estimatesFilters.inUseOption || inUseFilterOptions[0].value}
                labelPlacement='end'
                sx={{ border: '2px solid #EBECEF', padding: '8px', borderRadius: '8px', boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)' }}
              />
            </Grid>
            <Grid item>
              <Typography variant='h6'> Assigned Filter: </Typography>
              <RadioGroup
                options={assignFilterOptions}
                onChange={(e, newValue) => handleAssignedOptionChange(newValue)}
                value={estimatesFilters.assignedOption || assignFilterOptions[0].value}
                labelPlacement='end'
                sx={{ border: '2px solid #EBECEF', padding: '8px', borderRadius: '8px', boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)' }}
              />
            </Grid>
            <Grid item>
              <Typography variant='h6'> Due Date Filter: </Typography>
              <RadioGroup
                options={dueDateFilterOptions}
                onChange={(e, newValue) => handleDueTodayOptionChange(newValue)}
                value={estimatesFilters.dueDateOption || dueDateFilterOptions[0].value}
                labelPlacement='end'
                sx={{ border: '2px solid #EBECEF', padding: '8px', borderRadius: '8px', boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)' }}
              />
            </Grid>
            <Grid item container alignItems='flex-end' flexWrap='nowrap' width='fit-content'>
              <Button
                variant='outlined'
                sx={{
                  width: '80px',
                  height: '34px',
                  marginTop: '5px',
                  backgroundColor: '#FFF',
                  color: colors.blue[700],
                  '&:hover': {
                    color: 'white'
                  }
                }}
                onClick={onResetSearchInput}
              >
                Clear Filters
              </Button>
              <IconButton
                style={{
                  marginLeft: '10px',
                  marginTop: '5px'
                }} onClick={() => setRefresh(true)}
              >
                <RefreshIcon />
              </IconButton>
              <TextField
                size='small'
                style={xlScreen ? {} : { minWidth: '50%' }}
                placeholder='Search By ID'
                value={estimatesFilters.estimateNumber || ''}
                onChange={handleChangeEstimateNumber}
                type='number'
              />
            </Grid>
            {isColumnView && <Grid container justifyContent='flex-end' alignItems='flex-end' item flex={1}>
              <CreateEstimateModal setIsOpen={setOpen} isOpen={open} />
            </Grid>}
            {!isColumnView && <Grid container justifyContent='flex-end' alignItems='flex-end' item flex={1}>
              <CreateEstimateModal setIsOpen={setOpen} isOpen={open} />
              <TablePagination
                callToApi={(start, limit) => { fetchEstimates(getParams(), start, limit) }}
                totalItems={totalEstimates}
                rowsPerPageOptions={ROWS_PER_PAGE_EXTENDED}
              />
            </Grid>}
          </Grid>
          {!isColumnView ? (
            <Table
              headers={tableHeaders}
              defaultOrder='desc'
              rowAction={handleClickRow}
              callToApi={(start, limit) => { fetchEstimates(getParams(), start, limit) }}
              loading={tableLoading}
              hasPagination={false}
              onRightClickRow={handleClickRow}
              className={!xlScreen ? classes.table : ''}
              stickyHeader
            >
              {estimates?.map(estimate => ({
                ...estimate,
              })) ?? []}
            </Table>
          ) : <ColumnBoard />}
        </Box>
      </PrivateLoader>
    </div >
  )
}
export default Estimates
