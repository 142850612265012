import { Grid } from '@mui/material';
import FileBox from 'src/components/UI/CustomUI/atoms/FileBox';
import FileBoxProps from 'src/components/UI/CustomUI/atoms/FileBox/types';

interface FileListProps {
    files: FileBoxProps[];
}

const FileList = ({ files }: FileListProps) => {
    return (
        <Grid direction={"column"} container spacing={2}>
            {files.map((file) => (
                <Grid item xs={12} sm={6} md={4} key={file.name}>
                    <FileBox {...file} />
                </Grid>
            ))}
        </Grid>
    );
};

export default FileList;
