import {
  AFFILIATE_TYPE,
  APPROVAL_TYPE,
  CLIENT_CATEGORY,
  CLIENT_TYPE,
  CONTACT_ROLE,
  DISPATCH_STATUS,
  EMAIL_TYPE,
  ESTIMATE_PUBLIC_STATUS,
  ESTIMATE_STATUS,
  FEEDBACK_TYPE,
  FILE_TYPE,
  FORM_TYPE,
  INSURANCE_TYPE,
  ITEM_CATEGORY,
  ITEM_STATUS,
  LICENSE_TYPE,
  MESSAGE_STATUS,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  PROTECT_PLAN,
  REPAIR_TIMELINE,
  RESOURCE_TYPE,
  REVIEW_STATUS,
  SERVICE_STATUS,
  SUBSCRIPTION_STATUS,
  USER_TYPE,
} from '../helpers'
import { OrderType } from './orders/types'

export interface PunchlistFile {
  id: string
  name: string
  description: string
  fileType: FILE_TYPE
  fileUrl: string
  uploadedOn: number
  uploadedBy: string
}

export interface Address {
  city: string
  state: string
  line_1: string
  line_2: string
  zipCode: string
  latitude: number
  longitude: number
  fullAddress: string
  timeZone?: string
  country?: string
  county?: string
  googleUrl?: string
}

export interface Email {
  id: string
  emailType: EMAIL_TYPE
  email: string
  name: string
  description: string
}

export interface LicenseTypeInfo {
  id: string
  name: string
  displayName: string
  description: string
}

export interface License {
  id: string
  licenseType: LICENSE_TYPE
  licenseTypeInfo: LicenseTypeInfo
  licenseId: string
  reviewStatus: REVIEW_STATUS
  fileName: string
  validFromDate: number
  validToDate: number
  approvedOn: number
  approvedBy: string
  fileLocation: string
  uploadedBy: string
  uploadedOn: number
  territories: string[]
}

export interface LicenseType {
  companyName?: string
  contactName?: string
  email?: string
  phone?: string
  license?: License
  vendorId?: string
  territories?: Territory[]
}

export interface Insurance {
  id?: string
  validFromDate: number
  validToDate: number
  insuranceType: INSURANCE_TYPE
  reviewStatus: REVIEW_STATUS
  fileName: string
  insuranceCompanyName: string
  amountOfCoverage: number
  additionalInsured: boolean
  territories: string[]
  fileLocation: string
  insuranceId?: string
  reviewedBy: string
  reviewedOn: number
  uploadedBy: string
  uploadedOn: number
}

export interface TradeType {
  id: string
  name: string
  displayName: string
}

export interface Trade {
  expiration?: string
  license?: string
  tradeType: TradeType
  primaryTrade: boolean
  level: number
}

export interface Territory {
  id: string
  title: string
  zipCodes: string[]
  visible: boolean | null
  phone: string | null
  email: string
  emailName: string
  serviceable: boolean | null
  inspectionTerritory: boolean | null
  successManager: string | null
  territoryManager: string | null
  latitude: number | null
  longitude: number | null
  jobMinimum: number | null
  territoryTimeZone: string
  vipEmails: string[] | null
  ccEmails: string | null
  fullEmail: {
    id: string | null
    emailType: EMAIL_TYPE | null
    email: string
    name: string
    description: string | null
  }
}

export interface Region {
  type: string
  values: string[]
}

export interface VendorTerritory {
  id: string
  title: string
  status: REVIEW_STATUS
  capacity: number | null
  validGL: boolean
  validWC: boolean
}

export interface VendorOrder {
  assignerId: string | null
  dispatchId: string | null
  estimateId: string | null
  orderId: string | null
  pay: number | null
}

export interface Vendor {
  id: string
  firstName: string
  middleName: string
  lastName: string
  email: Email[]
  phone: number | string
  picture: string
  address: Address
  type: string
  insertedOn: number
  vendorId: string
  licenseList: License[]
  insuranceList: Insurance[]
  tradeList: Trade[]
  territoryList: VendorTerritory[]
  activeOrders: VendorOrder[]
  completedWorkOrders: VendorOrder[]
  reviewWorkOrders: VendorOrder[]
  companyName: string
  promoCode: string
  tosAccepted: true
  tosAcceptedOn: number
  bgCheckAccepted: true
  payoutEnabled: true
  stripeId: string
  jobCount: number
  qbId: string
  workOrderCount: number
  workOrderCountCompleted: number
  capacity: number
  registrationCompleteOn: number
  approved: boolean
  hasACH: boolean
  hasW9: boolean
  hasTaxID: boolean
  isAdmin: boolean

  // Additional fields
  verificationStage: string
  notes: string
  referral: string
  bgChecked: boolean
  bgPassed: boolean
  statistics?: VendorStatistics
  jobs?: VendorStatistics
  contact?: string
  territories?: string
  approvedOn?: number
  approvedBy?: string
  approvedByName?: string
  wcExemptTerritories: string[]
  earnedTotal: string
  distance?: string
  rank?: number
  color?: string

  lmsEnabled: boolean
  registrationComplete: boolean
  networkIds?: any[]
  parents?: any
}

export interface Partner {
  id: string
  active: boolean
  logo: string
  titleImage: string
  discount: string
  discountVerbiage: string
  title: string
  description: string
  tags: {
    id: string
    name: string
  }[]
  customerTypes: string[]
  actionVerbiage: string
  actionUrl: string
  readableCompanyName: string
}

export interface Client {
  id: string
  firstName: string
  middleName: string
  lastName: string
  email: Email[] | string
  phone: number | string
  address: Address
  type: string
  clientType: CLIENT_TYPE
  affiliate: string | null
  customerId: string
  isAdmin: boolean
  registrationCompleteOn: number
  region?: Region

  // Additional fields
  fullName?: string
  fullAddress?: string
  notes?: string
  statistics?: ClientStatistics[]
  createdOn?: number
  insertedOn?: number
  nar?: boolean | null
  revSharePriority?: string | null
  clientPriceModelId?: string | null
}

export interface ClientStatistics {
  status: string
  count: number
}

export interface PriceModel {
  id: string
  name: string
  discount: number
}

export interface ClientPriceModel {
  priceModels: PriceModel[]
}

export interface VendorStatistics {
  id: string
  completedMTD: string
  completedYTD: string
  jobs: string
  activeJobs: string
}

export interface JobTerritory {
  id: string
  title: string
  zipCodes: string[]
  visible: boolean
  phone: string
  email: string
  emailName: string
  serviceable: boolean
  successManager: string
  territoryManager: string
  jobMinimum?: number
  territoryTimeZone: string
}

export interface Contact {
  id?: string | null
  contactId?: string | null
  firstName: string
  lastName: string
  email: string
  phone: string
  role: CONTACT_ROLE | string | null
  approvalType: string | null
  approvedOn: number | null
  isScheduler: boolean
  isRequester: boolean
  fullName: string
  sendEstimate: boolean
  isPayer: boolean
  isInitialAuthorizer?: boolean | null
  vendorSearch?: string
  otherRole?: string
  preferredCommunicationMethods?: COMMUNICATION_METHODS[]
  insertedOn?: number
  zipCode?: string
  tosAccepted?: boolean
  companyName?: boolean
  approvalFlowInProgress?: boolean
  contractSignedOn?: number
  agentAcknowledgementSignedOn?: number
}

export interface PunchlistFile {
  id: string
  name: string
  description: string
  fileType: FILE_TYPE
  fileUrl: string
  uploadedOn: number
  uploadedBy: string
  uploadedAfterJob: string
  userType: string
  uploadedByUserType: string
  uploadedByName?: string
}

export enum COMMUNICATION_METHODS {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
}

export interface JobProperties {
  address: Address
  clientName: string
  files: PunchlistFile[]
  territory: JobTerritory
  publicId: string
  affiliateType: string
  affiliate: string | null
  closingDate: number
  userId: string
  invoiceId: string
  clientNotes: string
  accessDetails: string
  payAtClose: boolean
  preferredCompletedOn: number
  taxRate: number
  taxAmount: number
  discount: number
  minimum: number
  evaluationFee: number
  contacts: Contact[]
  fullAddress: string
  totalValue: number
  totalEstimated: number
  excludedFromReports: boolean
  archived: boolean
  estimateDeliveredOn?: number
  propertyToBeSold: boolean
  lockBoxDetails: string
  estimateFormType: string
  nar?: boolean
  propertyImageUrl?: string
  priceMatched?: boolean
}

export interface JobItem {
  id: string
  estimateId: string
  properties: JobProperties
  status: string
  customerCareEmployeeId: string
  publicStatus: string
  inCollection: boolean
  writtenOff: boolean
  percentItemsComplete: number
  createdOn: number
  createdBy: string
  dueOn: number
  expectedCompletedOn: number
  completedOn: number
  completedBy: string
  lastModifiedOn: number
  lastModifiedBy: string
  startedOn: number
  paymentStatus: string
  territoryManagerId: string
  territoryManagerName: string
  successManagerId: string
  successManagerName: string
  superintendentId: string
  supervisorId: string
  supervisorName: string
  nextTask: string
  unapprovedTotal: number
  materialCost: number
  customerPaid: number
  taxesTotal: number
  customerInvoicedTotal: number
  pendingInvoiceTotal: number
  totalProfit: number
  totalCost: number
  vendorPaid: number
  vendorOutstanding: number
  margin: number
  net: number
  orderHistory?: OrderHistory[]
  csm?: string
  affiliate?: string
  firstDispatchDate?: number
  businessDevelopmentManagerName?: string
  businessDevelopmentManagerId?: string
  superintendentName?: string
  fileDetails: PunchlistFile[]
  schedulingStartDate?: number
  lastDispatchDate?: number
  preConInspectionDate?: number
  finalWalkthroughDate?: number
  schedulingEndDate?: number
  lastSiteVisitDate?: number
  readyForCompletion?: boolean
  clientProjectManagerId?: string
}

export interface Payer {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: number
  role: CONTACT_ROLE
  sendEstimate: boolean
  isPayer: boolean
  approvalType: APPROVAL_TYPE
  approvedOn: number
  isScheduler: boolean
  isRequester: boolean
  isInitialAuthorizer: boolean
  otherRole: string
  preferredCommunicationMethods: COMMUNICATION_METHODS[]
  requester: boolean
  payer: boolean
  scheduler: boolean
  fullName: string
}

export interface PaymentOption {
  id: string
  title: string
  date: number
  day: string
  subtitle: string
  enable: boolean
  best: boolean
  price: number
  days: number
}

export interface EstimateProperty {
  address: Address
  files: PunchlistFile[]
  territory: Territory
  publicId: string
  affiliateType: AFFILIATE_TYPE
  financingApproved?: boolean;
  financingApprovedOn?: number;
  financingRequestedOn?: number;
  installmentThreshold: number;
  affiliate: string
  closingDate: number
  contacts: Contact[]
  userId: string
  invoiceId: string
  estimateFormType: FORM_TYPE
  nar: boolean
  shareEmailList: string[]
  propertyToBeSold: boolean
  clientNotes: string
  accessDetails: string
  payAtClose: boolean
  preferredCompletedOn: number
  estimateDeliveredOn: number
  lockBoxDetails: string
  totalValue: number
  taxRate: number
  taxAmount: number
  discount: number
  minimum: number
  evaluationFee: number
  totalEstimated: number
  archived: boolean
  excludedFromReports: boolean
  revSharePriority: string
  payer: Payer
  fullAddress: string
  clientName: string
  totalWithTax: number
  propertyImageUrl: string
  dueOn: number
  propertyUnderContract: boolean
  estimateInspection: EstimateInspection

  // new property
  clientRequestNotes: string
  priceMatched?: boolean
  id?: string

  repairTimeline?: REPAIR_TIMELINE
  requester?: EstimateContact
}

type EstimateInspection = {
  id: string
  inspector: InspectorCompany
}

export interface EstiamteDeliveryService {
  affiliateId: string | null
  clientCategory: CLIENT_CATEGORY
  id: string
  title: string | null
  days: number | null
  cost: number | null
  serviceable: boolean | null
  date: number | null
  formType: FORM_TYPE | null
  disabled: boolean | null
  onlyBusinessDays: boolean | null
}

export interface EstimateItem {
  id: string
  properties: Partial<EstimateProperty>
  approvalFlowStartedOn: number | null
  depositPaidOn: number | null
  deliveryService: EstiamteDeliveryService
  fileDetails: PunchlistFile[]
  status: ESTIMATE_STATUS
  publicStatus: string
  createdOn: number
  createdBy: string
  lastModifiedOn: number
  lastModifiedBy: string
  dueOn: number
  deliveredOn: number
  deliveryServiceId: string
  deliveryServiceCost: number
  promotionId: string
  repairTimeline: REPAIR_TIMELINE
  expedited: boolean
  approvedOn: number
  approvedBy: string
  approvalStatus: string
  hubspotProperties: string
  jobNeedsAttention: boolean
  formType: FORM_TYPE
  calendlyEventId: string

  // New properties
  estimatorName: string | null
  itemCount: number
  totalLaborHours: number
  unadjustedTotal: number
  totalCost: number
  totalWithDiscountApplied: number
  discountAmount: number
  profit: number
  margin: number

  currentlyInUse: boolean
  estimatorId: string | null

  sharedByEmail?: string
  sharedByName?: string

  onHold?: boolean
  holdNotes?: string
  awaitingFollowUp?: boolean

  minPriceRange?: number | null
  maxPriceRange?: number | null

  propertyToBeSold: boolean
  clientNotes: string

  shouldUpdateRole?: boolean
  success?: true
  marketingProperties?: {
    source?: string
    medium?: string
    campaign?: string
    content?: string
    promo?: string
  }
  lastDeliveredOn?: number
  dontCreateAccount?: boolean
  serviceable?: boolean
}

export interface NotificationItem {
  id: string
  userId: string
  readTimestamp: number
  estimateId: string
  commentId: string
  insertedBy: string
  insertedOn: number
  updatedBy: string
  updatedOn: number
  title: string
  message: string
}

export interface Job {
  start: string
  limit: string
  total: string
  items: JobItem[]
  counts: Count[]
  jobsCSV: string
}

export interface Estimate {
  start: string
  limit: string
  total: string
  totalValue: string
  dueToday: string
  items: EstimateItem[]
  relatedItems: EstimateItem[]
  counts: Count[]
  /*  jobsCSV: string */
}

export interface EstimateContact {
  id?: string | null
  firstName: string
  lastName: string
  email: string
  phone: string
  role: string | null
  approvalType: string | null
  approvedOn: number | null
  isScheduler: boolean
  isRequester: boolean
  fullName: string
  sendEstimate: boolean
  isPayer: boolean
  isInitialAuthorizer?: boolean | null
  vendorSearch?: string
  otherRole?: string
  preferredCommunicationMethods?: COMMUNICATION_METHODS[]
  insertedOn?: number
  zipCode?: string
  tosAccepted?: boolean
  companyName?: boolean
}

export interface EstimateProperties {
  id?: string
  address: Address
  files?: PunchlistFile[]
  territory?: Territory
  publicId?: string
  affiliate?: string
  closingDate?: number | null
  contacts?: Array<Contact>
  userId: string
  clientNotes?: string
  clientRequestNotes?: string

  totalValue?: number
  taxRate?: number
  taxAmount?: number
  discount?: number
  minimum?: number
  evaluationFee?: number
  payAtClose?: boolean
  accessDetails?: string
  preferredCompletedOn?: number | null
  invoiceId?: string
  repairTimeline?: REPAIR_TIMELINE
  propertyToBeSold?: boolean
  nar?: boolean | null
  totalEstimated?: number | null
  totalWithTax?: number | null
  propertyImageUrl?: string | null
  fullAddress: string
  requester?: EstimateContact
  excludedFromReports: boolean
}

export interface EstimateType {
  id: string
  properties: Partial<EstimateProperties>
  status: ESTIMATE_STATUS
  publicStatus: ESTIMATE_PUBLIC_STATUS | string
  createdOn: number
  createdBy: string
  lastModifiedOn: number
  lastModifiedBy: string
  dueOn: number
  deliveredOn: number
  deliveryServiceId: string
  deliveryServiceCost: number
  formType: FORM_TYPE
  repairTimeline: REPAIR_TIMELINE
  approvedOn: number
  approvedBy: string
  approvalStatus: string
  propertyToBeSold: boolean
  clientNotes: string
  jobNeedsAttention?: boolean
  minPriceRange?: number | null
  maxPriceRange?: number | null
  promotionId: string | null
  calendlyEventId?: string | null

  shouldUpdateRole?: boolean
  success?: true
  sharedByEmail?: string
  sharedByName?: string
  marketingProperties?: {
    source?: string
    medium?: string
    campaign?: string
    content?: string
    promo?: string
  }
  lastDeliveredOn?: number
  dontCreateAccount?: boolean
}

export interface NavBarJob {
  start: string
  limit: string
  total: string
  items: JobItem[]
}

export interface Count {
  status: string
  count: number
}

export interface JobHistory {
  text: string
  createdOn: string
  createdBy: string
  estimateId: string
  resourceType: string
  resourceId: string
  createdByName?: string
}

export interface RemovedItem {
  text: string
  createdOn: number
  createdBy: string
  estimateId: string
  resourceType: string
  resourceId: string
  data: Item
}

export interface JobHistoryStatus {
  id: string
  jobId: string
  oldStatus: string
  newStatus: string
  createdOn: number
  createdBy: string
  ageInDays: number
  description: string
}

export interface OrderHistory {
  id: string
  jobId: string
  orderId: string
  vendorId: string
  sentOn: number
  sentBy: string
  expiresOn: number
  bidPrice: number
  orderValue: number
  scheduledStart: number
  scheduledEnd: number
  status: DISPATCH_STATUS
  repliedOn: number
  rejectType: REVIEW_STATUS
  vendorComment: string
  isSiteVisit: boolean
  createdByName?: string
  sendByName?: string
  vendorName?: string
  margin?: number
}

export interface ImageFile {
  id: string
  name: string
  description: string
  fileType: FILE_TYPE
  fileUrl: string
  uploadedOn: number
  uploadedBy: string
  uploadedAfterJob: true
  uploadedByUserType: string
  uploadedByUserName?: string
}

export interface Item {
  id: string | null
  orderId: string | null
  orderPublicId: string
  estimateId: string
  estimatePublicId: string
  itemId: string
  title: string
  category: ITEM_CATEGORY
  inspectionReportReference: string
  quantity: number | string
  laborRate: number
  unitLaborHours: number
  unitMaterialCost: number
  margin: number | string
  unitPrice: number
  unitCost: number
  status: ITEM_STATUS
  publicJobStatus: string
  disclaimer: string
  severity: string
  inspectionReportNote: string
  publicRepairNote: string
  requestedRepairNote: string
  fieldNote: string
  punchlistTechNote: string
  followupNotes: {
    id: string;
    createdOn: number;
    note: string;
    imageFiles: {
      type: string;
      fileUrl: string;
      name: string;
    }[];
  }[];
  imageFiles: ImageFiles[]
  siteVisitId: string
  estimatedPrice: number
  siteVisitNotes: string
  siteVisitComplete: true
  staffReviewed: boolean
  totalPrice: number
  totalCost: number
  totalLaborHours: number
  totalLaborCost: number | string
  totalMaterialCost: number
  createdByName: string
  createdOn: number
  requiredLicense?: boolean
  requiredPermit?: boolean
  priceMatch?: {
    comment?: string | null
    id?: string
    file?: PunchlistFile | null
  } | null
  // extra fields
  checked?: boolean
  itemsByCategory?: GroupedItem[]
  jobId?: string
  measurement: string
  maxPriceRange?: number
  minPriceRange?: number
  evaluateReason?: string
  profit: number
  inspectionReportNoteHeader?: string
  automatchConfidenceScore: null | number
  hideFromClient?: boolean | null

  uploaded?: boolean
  editable?: boolean
  htmlFiles?: File[]

  addedPostDelivery?: boolean
  consultationRequestedDate?: string
  consultationRequested?: boolean
}

export interface Widget {
  id: string
  name: string
  affiliate: string
  createdOn: number
  fontFamily: string
  headerFontSize: number
  contentFontSize: number
  fontColor: string
  imageUrl: string
  backgroundColor: string
  buttonFontFamily: string
  buttonFontSize: number
  buttonFontColor: string
  buttonColor: string
}

export interface GroupedItem {
  category?: string
  items: Item[]
  totalCost: number
  totalPrice: number
}

export interface ItemByCategory {
  category: ITEM_CATEGORY
  items: Item[]
  totalCost: number
  totalPrice: number
}

export interface ImageFiles {
  id: string
  name: string
  description: string
  fileType: FILE_TYPE
  fileUrl: string
  uploadedOn: number
  uploadedBy: string
  uploadedAfterJob: true
  uploadedByUserType: string
}

export interface WorkflowItem {
  id: string
  startedOn: number
  address: string
  status: string
  name: string
  affiliate: string
  affiliateType: string
  orderId: string
  type?: WORKFLOW_TYPES
  triggerDate: number
}

export enum WORKFLOW_TYPES {
  SETUP_JOB = 'SETUP_JOB',
  SCHEDULE_WORK_WITH_CLIENT = 'SCHEDULE_WORK_WITH_CLIENT',
  REVIEW_ESTIMATED_COMPLETION_DATE = 'REVIEW_ESTIMATED_COMPLETION_DATE',
  CLIENT_FINAL_REVIEW = 'CLIENT_FINAL_REVIEW',
  SEND_FINAL_INVOICE = 'SEND_FINAL_INVOICE',
  SCHEDULE_PRECON_INSPECTION = 'SCHEDULE_PRECON_INSPECTION',
  REVIEW_PRECON_INSPECTION = 'REVIEW_PRECON_INSPECTION',
  SUBMIT_CHANGE_ORDER = 'SUBMIT_CHANGE_ORDER',
  RECEIVE_CHANGE_ORDER_APPROVAL = 'RECEIVE_CHANGE_ORDER_APPROVAL',
  SCHEDULE_FINAL_WALKTHROUGH = 'SCHEDULE_FINAL_WALKTHROUGH',
  REVIEW_FINAL_WALKTHROUGH = 'REVIEW_FINAL_WALKTHROUGH',
  DISPATCH_ORDER = 'DISPATCH_ORDER',
  RECEIVE_VENDOR_RESPONSE = 'RECEIVE_VENDOR_RESPONSE',
  CONFIRM_WORK_START = 'CONFIRM_WORK_START',
  REVIEW_DISPATCH_STATUS = 'REVIEW_DISPATCH_STATUS',
  VENDOR_COMPLETION = 'VENDOR_COMPLETION',
  REVIEW_WORK = 'REVIEW_WORK',
  REQUEST_CORRECTIONS = 'REQUEST_CORRECTIONS',
  PAY_VENDOR = 'PAY_VENDOR',
  MARK_JOB_COMPLETE = 'MARK_JOB_COMPLETE',
  SKIP = 'SKIP',
}

export interface Workflow {
  start: string
  limit: string
  total: string
  items: WorkflowItem[]
}

export interface PCView {
  portfolioSummary?: PCPortfolioSummary
  portfolioCount?: PCPortfolioCount
  workOrders?: {
    gridData: PCWorkOrder[],
    pageNumber: number,
    numberOfPages: number,
    size: number,
  }
  pcDetails?: PCDetails
  pcFinancials?: PCFinanacials
  pcFinancialsJobs?: PCFinancialsJobs
  pcCSV?: string
}

export interface PCFinanacials {
  totalActiveWO: number,
  totalActiveContractValue: number,
  avgCompleteWOValue: number,
  avgCompleteWoValueThisMonth: number,
  avgActiveContractValue: number
}
export interface PCFinancialsJobs {
  totalActiveJobs: number,
  totalActiveContractValue: number,
  avgCompleteJobValue: number,
  avgCompleteJobValueThisMonth: number,
  avgActiveContractValue: number
}



export interface PCDetails {
  projectCoordinatorName: string,
  date: string,
  avgDaysComplete: number,
  businessDays: number,
  currentBusinessDay: number,
  totalOpenTasks: number,
  totalPastDue: number,
  totalDueToday: number,
  totalDueFuture: number,
}

export interface PCWorkOrder {
  id: string,
  orderPublicId: string,
  pendingProAssignment: boolean,
  jobStartDate: string,
  workItemTotal: number,
  notStarted: number,
  inProgress: number,
  pendingSelection: number,
  pendingQualityControl: number,
  workItemComplete: number,
  pendingInvoice: boolean,
  dispatchOrder: boolean,
  reworkPending: boolean,
  pendingCustomerSurvey: boolean,
  payAtClose: boolean,
  closingDate: string,
  propertyAddress: string,
  cityStateZip: string,
  dateOfOriginalContract: string | null,
  contacts: string,
  totalContractAmount: string,
  totalChangeOrderAmount: string,
  changeOrderPendingApproval: string,
  totalContract: string,
  estimatedDateOfCompletion: string,
  amountComplete: number | null,
  percWorkOrderComplete: string,
  contractAmountPending: number | null,
  depositReceived: boolean,
  milestoneOneReceived: boolean,
  milestoneTwoReceived: boolean,
  milestoneThreeReceived: boolean,
  comments: string | null,
  receiveVendorResponse: boolean,
}

export interface PCPortfolioSummary {
  coordinatorId: string,
  avgContractTotal: number,
  avgWoCompletionDay: number,
  changeOrderPendingApproval: number,
  customerSelectionPending: number,
  customerUpdatePending: number,
  woReviewCount: number,
  pendingReworkCount: number,
  woCompleteCount: number,
}

export interface PCPortfolioCount {
  notStarted: number,
  inProgress: number,
  pendingProAssignment: number,
  pendingQualityControl: number,
  pendingRework: number
  complete: number
  invoiced: number
}

export interface WorkOrders {
  generalSection?: WorkOrderGeneralSection
  financialSection?: WorkOrderFinancialSection
  contractDetails?: WorkOrderContractDetails
  contractMilestones?: {
    milestones: WorkOrderMilestone[]
  }
  items?: WorkOrderItem[]
}

export interface WorkOrderGeneralSection {
  workOrderName: string
  territory: string
  territoryTimezone: string
  address: string
  city: string
  state: string
  zipCode: string
  projectCoordinator: {
    fullName: string
    phone: string
    email: string
  }
  fieldManager: {
    fullName: string
    phone: string
    email: string
  }
}

export interface WorkOrderFinancialSection {
  originalContractAmount: number | null
  changeOrderAmount: number | null
  totalContract: number | null
  estimatedDateCompletion: string | null
  amountWorkOrderComplete: string | null
  remainingContractAmount: number | null
  discountApplied: string | null
}

export interface WorkOrderContractDetails {
  payAtClose: boolean | null
  customerFullName: string | null
  customerPhone: string | null
  customerEmail: string | null
  phoneCommunication: boolean | null
  smsCommunication: boolean | null
  emailCommunication: boolean | null
  agentFullName: string | null
  agentPhone: string | null
  agentEmail: string | null
  agentBrokerage: string | null
  dateSigned: number | null
}

export interface WorkOrderMilestone {
  milestone: number | null
  customerPrice: number | null
  proPrice: number | null
  pmApproved: boolean | null
  customerApproved: boolean | null
}

export interface WorkOrderItem {
  comments?: string
  customerPrice?: string
  dateApprovedByCustomer?: string
  dateMaterialSelectionComplete?: string
  dateOfScheduledService?: string
  dateProComplete?: string
  dateQualityReviewComplete?: string
  description?: string
  itemId?: string
  jobStartDate?: string
  materialSelectionLink?: string
  orderDate?: string
  originalContract?: boolean
  proContactPhone?: string
  proDateAssigned?: string
  proLicenseStatus?: string
  proName?: string
  proPrice?: string
  requiresMaterialSelection?: boolean
  reworkRequired?: boolean
  status?: string
  tradeCategory?: string
  workOrderItem?: string
}

export interface Notification {
  start: string
  limit: string
  total: string
  items: NotificationItem[]
}
export interface Feedback {
  resourceId: string
  resourceType: FEEDBACK_TYPE
  ratings: [
    {
      description?: string
      score: number
    }
  ]
  comments?: string
}
export interface OrderBid {
  bidPrice: number
  vendorProfit: number
  marginPct: number
}

export interface PaymentType {
  start: string
  limit: string
  total: string
  items: Payment[]
}

export interface Payment {
  id: string
  createdOn: number
  createdBy: string
  createdByName: string
  type: PAYMENT_TYPE
  amount: number
  resourceId: string
  resourceType: RESOURCE_TYPE
  status: PAYMENT_STATUS
  stripeSourceId: string
  stripeTransactionId: string
  stripePaymentId: string
  stripeFee: number
  netAmount: number
  payerEmail: string
  memo: string
  address: string
  isRefund: boolean
}

export interface Message {
  id: string
  jobId: string
  messageId: string
  type: string
  sender: RecipientMessage
  recipients: RecipientMessage[]
  ccRecipients: RecipientMessage[]
  bccRecipients: RecipientMessage[]
  body: string
  status: MESSAGE_STATUS
  sendAt: number
  error: string
  readAt: {
    additionalProp1: number
    additionalProp2: number
    additionalProp3: number
  }
  subject: string
  threadId: string
  parentId: string
}

export interface EmailEventItem {
  id: string
  insertedOn: number
  recipent: Email
  sender: Email
  event: string
  subject: string
}

export interface EmailEvents {
  start: number
  limit: number
  total: number
  items: EmailEventItem[]
}

export interface RecipientMessage {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  fullName: string
  type?: string
}

export interface ContactsMessage extends RecipientMessage {
  type: CONTACTS_MESSAGE_TYPES
}

export enum CONTACTS_MESSAGE_TYPES {
  BCCRECIPIENTS = 'bccRecipients',
  CCRECIPIENTS = 'ccRecipients',
  RECIPIENTS = 'recipients',
}

export interface VendorCount {
  status: string
  count: number
}

export interface MaterialInvoice {
  invoiceNum: string
  amount: number
  orderName: string
  orderId?: string
  insertedOn: number
  fileUrl: string
}

export interface CompanyItem {
  id: string
  name: string
  adminName: string
  email: string
  affiliateId: string
  institutional: boolean
  hasCustomDeliveryService: boolean | null
  createdOn: number
  landingPageUrl: string
  phone: number
  logo: {
    id: string
    name: string
    description: string
    fileType: string
    fileUrl: string
    uploadedOn: number
    uploadedBy: string
    uploadedAfterJob: boolean
    uploadedByUserType: string
    uploadedByUserName: string
    fileName: string
  }
}

export interface InspectionCompany {
  name: string
  id?: string
  address?: Partial<Address>
  inspectors?: InspectorCompany[]
}

export interface InspectorCompany {
  id: string
  name: string
  email: string
  phone: string
  inspectionCompany: InspectionCompany
}

export interface MaintenanceService {
  title: string
  startMonth: number
  status: SERVICE_STATUS
  orderId: string
  jobId: string
  requestedDate: number
}

export interface Gift {
  id: string
  insertedOn: number
  message: string
  senderImageUrl: string
  shippingAddress: Address
  shippingName: string
  trackingId: string
  trackingUrl: string
  deliveryDate: number
  expeditedShippingCost: number
}

export interface SubscriptionItem {
  id: string
  protectPlan: PROTECT_PLAN
  title: string
  services: MaintenanceService[]
  homeowner: Client
  purchaser: Client
  propertyAddress: Address
  files: PunchlistFile[]
  status: SUBSCRIPTION_STATUS
  price: number
  discount: number
  insertedOn: number
  expiresOn: number
  contractSignedOn: number
  giftBox: Gift
  renewals: number
  stripeProductPriceId: string
  stripeSubscriptionId: string
  gift: boolean
  territory: Territory
  reference: REFERRAL_TYPE
}

export interface RepairListItem {
  id: string
  title: string
  category: string
  usageCount: number
  wins: number
  losses: number
  lastUsedTimestamp: number
  fixedPrice: null | boolean
  disclaimer: null | string
  hideFromClient: null | boolean
  severity: string
  laborHours: number
}

export enum REFERRAL_TYPE {
  EMAIL = 'EMAIL',
  SALES_TEAM = 'SALES_TEAM',
  WEBSITE = 'WEBSITE',
  MARKETING = 'MARKETING',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
}

export interface Match {
  priceItemId: string
  priceItemTitle: string
  confidenceScore: number
  scorePercent: number
  displayTitle: string
}
export interface MatchItem {
  itemId: string
  itemInspectionReportNote: string
  matches: Match[]
}

export interface MaterialProperty {
  id: string
  quantity: number
}
export interface PriceItemType {
  id: string
  publicTitle: string
  margin: number
  unitCost: number
  unitPrice: number
  laborRate: number
  unitLaborHours: number
  unitMaterialCost: any
  title: string
  category: string
  severity: string
  disclaimer: any
  searchIndex: string
  materials: null | MaterialProperty[]
  hideFromClient: boolean
  territoryId: string
  usageCount: number
  repairItem: null
  territory?: Territory
  profit: number
}

export interface MaterialItem {
  id: string
  sku: string
  url: string
  territoryPrice: {
    [key: string]: string
  }
}

export interface GroupedOrder {
  jobId: string
  publicId: string
  displayAddress: string
  territoryTitle: string
  affiliateId: string
  expectedCompletedOn: number
  orders: OrderType[]
}
