import { Box, Button, Dialog, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { promoCodesActions } from 'src/ducks/actions';
import { toast } from 'react-toastify';
import AntSwitch from 'src/components/UI/CustomUI/atoms/AntSwitch';
import DatePicker from "src/components/UI/CustomUI/atoms/DatePicker"
import Spinner from '../SpinnerLoader';
import { formatDateTimestamp } from 'src/helpers';

type Props = {
    open: boolean
    onClose: () => void
}

const CreatePromoCodeModal = (props: Props) => {
    const [code, setCode] = React.useState('')
    const [percentOff, setPercentOff] = React.useState<any>()
    const [amount, setAmount] = React.useState<any>()
    const [startDate, setStartDate] = React.useState<any>()
    const [expirationDate, setExpirationDate] = React.useState<any>()
    const [promotionType, setPromotionType] = React.useState<string>("FIXED")
    const [resourceType, setResourceType] = React.useState<'ESTIMATE' | 'SUBSCRIPTION'>('ESTIMATE')
    const [active, setActive] = React.useState(true)
    const [title, setTitle] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const dispatch = useDispatch();

    const handleClear = () => {
        setCode('')
        setAmount('')
        setPercentOff('')
        setStartDate('')
        setExpirationDate('')
        setTitle('')
        setPromotionType('FIXED')
        setResourceType('ESTIMATE')
        setActive(true)
    }

    const handleCreatePromoCode = () => {
        setLoading(true)
        dispatch(
            promoCodesActions.createPromoCode({
                code,
                percentOff,
                amount,
                expirationDate: formatDateTimestamp(expirationDate),
                startDate: formatDateTimestamp(startDate),
                promotionType: promotionType as any,
                resourceType,
                active,
                title,
                deliveryDays: 0,
                maxUses: 0,
            }, (succ) => {
                if (succ) {
                    handleClear()

                    props.onClose()
                    toast.success('Promo code created successfully')
                } else {
                    toast.error('Failed to create promo code')
                }
                setLoading(false)
            })
        )
    }

    const disabledButton = !code || !expirationDate || !title || !resourceType || !promotionType || (promotionType === 'FIXED' && !amount) || (promotionType === 'PERCENTAGE' && !percentOff)

    const handleClose = () => {
        handleClear()
        props.onClose()
    }
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '16px', padding: '16px' } }}
            fullWidth
        >
            <Box className={styles.container}>
                <Typography className={styles.title}>
                    Create Promo Code
                </Typography>
                <Box className={styles.inputContainer}>
                    <Box className={styles.inputContainer}>
                        <Typography className={styles.inputLabel}>Code</Typography>
                        <input
                            placeholder="Code"
                            value={code}
                            className={styles.input}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </Box>
                    <Box className={styles.inputContainer}>
                        <Typography className={styles.inputLabel}>Promotion Type</Typography>
                        <Select value={promotionType} onChange={(e) => setPromotionType(e.target.value as any)}>
                            <MenuItem value='FIXED'>Fixed</MenuItem>
                            <MenuItem value='PERCENTAGE'>Percentage</MenuItem>
                        </Select>
                    </Box>
                    {promotionType === "PERCENTAGE" && <Box className={styles.inputContainer}>
                        <Typography className={styles.inputLabel}>Percent Off</Typography>
                        <input
                            placeholder="Percent Off"
                            value={percentOff}
                            className={styles.input}
                            onChange={(e) => setPercentOff(Number(e.target.value))}
                        />
                    </Box>}
                    {promotionType === "FIXED" && <Box className={styles.inputContainer}>
                        <Typography className={styles.inputLabel}>Amount</Typography>
                        <input
                            placeholder="Amount"
                            value={amount}
                            className={styles.input}
                            onChange={(e) => setAmount(Number(e.target.value))}
                        />
                    </Box>}
                    <Box className={styles.inputContainer}>
                        <Typography className={styles.inputLabel}>Start Date</Typography>
                        <DatePicker allowTimePicker minDate={new Date()} placeholder='mm/dd/yyyy' value={startDate} onChange={(value) => setStartDate(value)} />
                    </Box>
                    <Box className={styles.inputContainer}>
                        <Typography className={styles.inputLabel}>Expiration Date</Typography>
                        <DatePicker allowTimePicker minDate={startDate || new Date()} placeholder='mm/dd/yyyy' value={expirationDate} onChange={(value) => setExpirationDate(value)} />
                    </Box>
                    <Box className={styles.inputContainer}>
                        <Typography className={styles.inputLabel}>Title</Typography>
                        <input
                            placeholder="Title"
                            value={title}
                            className={styles.input}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Box>
                    <Box className={styles.inputContainer}>
                        <Typography className={styles.inputLabel}>Resource Type</Typography>
                        <Select value={resourceType} onChange={(e) => setResourceType(e.target.value as any)}>
                            <MenuItem value='ESTIMATE'>Estimate</MenuItem>
                            <MenuItem value='SUBSCRIPTION'>Subscription</MenuItem>
                        </Select>
                    </Box>
                    <Box className={styles.inputContainer}>
                        <Typography className={styles.inputLabel}>Active</Typography>
                        <AntSwitch value={active} defaultChecked onChange={(e) => setActive(e.target.checked)} />
                    </Box>
                    <Box style={{ height: "16px" }} />
                    <Button disabled={disabledButton} variant="contained" color="primary" fullWidth onClick={handleCreatePromoCode}>{loading ? <Spinner color="#fff" size={16} /> : `Create`}</Button>
                </Box>
            </Box>
        </Dialog>
    )
}

export default CreatePromoCodeModal