import { isWeekend } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {
  capitalize as capitalizeLodash,
  isEmpty as isEmptyLodash,
  isEqual,
  reduce,
} from 'lodash'
import moment from 'moment-timezone'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { OrderType } from 'src/ducks/orders/types'
import {
  ContactsMessage,
  GroupedItem,
  License,
  Message,
  RecipientMessage,
} from 'src/ducks/types'
import theme from '../components/UI/MaterialUI/theme'
import {
  AFFILIATE_TYPE,
  CONTACT_ROLE,
  DISPATCH_STATUS,
  EXPIRACY_STATUS,
  ITEM_CATEGORY,
  ORDER_ITEM_STATUS,
  ORDER_STATUS,
  REVIEW_STATUS,
} from './constants'
import { enumToArrayObject, getOptionsFromArray } from './options'

export * from './category'
export * from './constants'
export * from './datefns'
export * from './downloadFile'
export * from './files'
export * from './filesUrlParser'
export * as history from './history'
export * from './options'
export * from './states'
export * from './useClassName'

/** MASKS *******************************/
export const PHONE_MASK_INPUT = [
  /[1-9]/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const PHONE_MASK_REGEXP =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/ ///^([0-9]+(-[0-9]+)+)$/

export const PHONE_MASK_REGEXP_NO_SCOPE = /^([0-9]+([0-9]+)+)$/

export const CURRENCY_MASK = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}
export const CURRENCY_MASK_NEGATIVE = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: true,
  allowLeadingZeroes: false,
}
export const PORCENT_MASK = {
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 3, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

export const AMOUNT_MASK = (decimals: number) => {
  return {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: decimals, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  }
}

export const unMaskedInput = (value: string) =>
  value?.replace('$', '')?.replace(/,+/g, '')
/****************************************/
export const unMaskedInputWithReplace = (value: string, replace: string) =>
  value?.replace(replace, '')?.replace(/,+/g, '')

/** EMAILS ******************************/
export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
/****************************************/

/** PASSWORDS ***************************/
export const containsLowercase = (str: string): boolean => /[a-z]/.test(str)
export const containsUppercase = (str: string): boolean => /[A-Z]/.test(str)
export const containsNumber = (str: string): boolean => /\d/.test(str)
export const containsLetter = (str: string): boolean => /[A-Za-z]/.test(str)
export const containsNumberLetter = (str: string): boolean =>
  containsNumber(str) && containsLetter(str)

export const validatePassword = (psw: string): boolean =>
  psw.length >= 6 &&
  containsUppercase(psw) &&
  containsLowercase(psw) &&
  containsNumberLetter(psw)
/****************************************/

/** OBJECTS *****************************/
export const isEmpty = (object: any): boolean => {
  if (typeof object === 'string') {
    return isEmptyLodash(object.trim())
  }
  return isEmptyLodash(object)
}
/****************************************/

/** STRINGS *****************************/
export const capitalize = (string?: string | undefined): string => {
  return capitalizeLodash(string)
}

export const formatPhone = (
  phone: string | number | null | undefined
): string => {
  if (phone) {
    const phoneCopy = phone.toString()
    if (phoneCopy.length === 10) {
      return (
        '+' +
        phoneCopy
          .substring(0, 3)
          .concat(' ')
          .concat(phoneCopy.substring(3, 6))
          .concat(' ')
          .concat(phoneCopy.substring(6))
      )
    }
  }
  return ''
}

export const validatePhone = (phone: string): boolean => {
  return (
    PHONE_MASK_REGEXP.test(String(phone).toLowerCase()) && phone.length <= 10
  )
}

export const concatSeparator = (
  fstStr: string,
  sndStr: string,
  separator: string
): string => {
  return fstStr.concat(
    fstStr && !isEmpty(fstStr) && sndStr && !isEmpty(sndStr) ? separator : '',
    sndStr || ''
  )
}

export const errorTextTryingTo = (text: string): string => {
  if (!isEmpty(text))
    return 'An error occurred trying to '
      .concat(text)
      .concat(". We'll fix it soon!")
  return "An unexpected error occurred. We'll fix it soon!"
}

export const collapseString = (text: string, max: number): string => {
  return text.length > max ? `${text.slice(0, max)}...` : text
}

export const formatSender = (
  name: string,
  lastName: string,
  email: string
): string => {
  return `${name} ${lastName} (${email})`
}

export const formatReceiver = (
  name: string,
  lastName: string,
  email: string
): string => {
  return `To: ${name} ${lastName} (${email})`
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
/****************************************/

/** DATES *******************************/

export const timeFromNow = (timestamp: number): string => {
  const now = new Date().getTime() / 1000 // s
  const hours = (now - timestamp / 1000) / 3600 // hs
  const days = hours / 24
  const minutes = (now - timestamp / 1000) / 60 // m
  if (days > 30) {
    return "Month's ago"
  }
  if (days > 7) {
    return "Week's ago"
  }
  if (days >= 1) {
    return Math.trunc(days) + 'Days'
  }
  if (hours >= 1) {
    return Math.trunc(hours) + 'H'
  }
  if (minutes > 1) {
    return Math.trunc(minutes) + 'M'
  }
  return Math.trunc((new Date().getTime() - timestamp) / 1000).toFixed(0) + 'S'
}

export const daysFromNow = (timestamp: number): number => {
  const now = new Date().getTime() / 1000 // s
  const hours = (now - timestamp / 1000) / 3600 // hs
  const days = hours / 24
  return days
}

export const formatTimestampUTC = (
  timestamp: number | null | undefined,
  format: string
): string => {
  if (timestamp !== null && timestamp !== undefined) {
    const date: Date = new Date(timestamp * 1000)

    return `${date.getUTCMonth() < 9 ? '0' : ''}${date.getUTCMonth() + 1}/${date.getUTCDate() < 10 ? '0' : ''
      }${date.getUTCDate()}/${date.getUTCFullYear()}`
  }
  return ''
}

export const formatTimestamp = (
  timestamp: number | null | undefined,
  format: string,
  timezone?: string
): string => {
  if (timestamp !== null && timestamp !== undefined) {
    const date: Date = new Date(timestamp * 1000)
    if (!timezone) return moment(date).utc().format(format)
    else return moment(date).tz(timezone).format(format)
  }
  return ''
}

export const formatTimestampTimeZone = (
  timestamp: number | null | undefined,
  format: string,
  timeZone: string
): string => {
  if (timestamp !== null && timestamp !== undefined) {
    var utcDate = new Date(timestamp * 1000)
    var result = utcToZonedTime(utcDate, timeZone)
    //const date: Date = new Date(timestamp * 1000)
    return moment(result).format(format)
  }
  return ''
}

export const formatDate = (
  timestamp: number | null | undefined,
  format: string
): string => {
  if (timestamp !== null && timestamp !== undefined) {
    const date: Date = new Date(timestamp)
    return date.toLocaleDateString('en-US')
  }
  return ''
}

export const formatTimestampDate = (
  timestamp: number | null,
  timeZone?: string
): Date => {
  if (timestamp !== null) {
    if (typeof timeZone !== 'undefined') {
      var utcDate = new Date(timestamp * 1000)
      var result = utcToZonedTime(utcDate, timeZone).getTime()
      return new Date(result)
    }
    return new Date(timestamp * 1000)
  }
  return new Date()
}

export const formatUTCTimestampDate = (timestamp: number | null): Date => {
  if (timestamp !== null) {
    const date: Date = new Date(timestamp * 1000)
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    )
  }
  return new Date()
}

export const formatDateToUTCTimestamp = (
  date: Date,
  time?: 'endOfDay'
): number => {
  let hours = date.getUTCHours()
  let minutes = date.getUTCMinutes()
  if (time === 'endOfDay') {
    hours = 23
    minutes = 59
  }
  return (
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hours,
      minutes
    ) / 1000
  )
}

export const formatDateTimestamp = (date: Date, timeZone?: string): number => {
  if (date !== null) {
    if (typeof timeZone !== 'undefined') {
      const utcDate = zonedTimeToUtc(date, timeZone)
      return Math.floor(utcDate.getTime() / 1000)
    }
    return Math.floor(date.getTime() / 1000)
  }
  return 0
}

export const dateFormatString = (
  date: string | Date,
  format = 'MM/DD/YYYY'
): string => {
  let result = ''
  let toFormat

  if (date instanceof moment) toFormat = date
  else if (date !== null)
    toFormat = typeof date === 'string' ? moment(date) : date

  if (moment(date).isValid()) {
    result = moment(toFormat).format(format)
  }
  return result
}
/****************************************/

/** NUMBERS *****************************/

/* export const round = (value: number, exp: number) => {
  if (value === null || value === undefined) return '0.'.concat('0'.repeat(exp))
  const roundedPrice = (Math.round(value * (10 * exp)) / (10 * exp)).toFixed(2)
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
  })
  return formatter.format(Number(roundedPrice))
} */

//Replaced round function cause it was rounding 5.89 to 5.90 and other bugs
export const round = (value: number, exp: number) => {
  if (value === null || value === undefined) return '0.'.concat('0'.repeat(exp))

  const roundedPrice = (
    Math.round((value + Number.EPSILON) * 100) / 100
  ).toFixed(2)
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  })
  return formatter.format(Number(roundedPrice))
}

/* eslint-disable */
export const roundWithoutCents = (value: number) => {
  const roundedPrice = (
    Math.round((value + Number.EPSILON) * 100) / 100
  ).toFixed(0)
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
  })
  return formatter.format(Number(roundedPrice))
}

export const isPercentageOf = (amount: number, total: number): number => {
  return Math.floor((amount / total) * 100)
}
/****************************************/

export const privacyLink = 'https://www.bosscathome.com/privacy/'

interface IFormatParams {
  allowFalsies: boolean
}

export const formatParams = (params: any, settings?: IFormatParams) => {
  if (!params) return ''
  const paramsFormated = Object.keys(params)
    .filter((key) => {
      return (
        (params[key] !== undefined &&
          params[key] !== null &&
          key !== 'sortBy' &&
          key !== 'sortOrder') ||
        (settings && settings.allowFalsies)
      )
    })
    .map((key) => {
      if (typeof params[key] == 'boolean') {
        return `${key}=${params[key]}`
      }

      const value = encodeURIComponent(params[key])
      if (key === 'statusSearch') {
        const status = params[key].reduce((acc: any, curr: string) => {
          return [...acc, curr.toUpperCase().replace(/\s/g, '_')]
        }, [])
        return `&status=${status.join(',')}`
      }

      if (key === 'statusComp') {
        const status = params[key]
        return `paymentStatus=${status.replace(/\s/g, '_')}`
      }
      return value ? `${key}=${value}` : ''
    })
    .filter((val) => !!val)
    .join('&')
  return paramsFormated
}

export const vstagesTagColor = (state: string) => {
  switch (state) {
    case 'Not Contacted':
      return {
        color: 'white',
        background: 'var(--gray700)',
      }
    case 'Verified':
      return {
        color: 'var(--green900)',
        background: 'var(--green100)',
      }
    case 'Lead':
      return {
        background: 'var(--red100)',
        color: 'var(--red800)',
      }
    case 'Document Pending Review':
      return {
        color: 'var(--gray700)',
        background: '#FFEA00',
      }
    default:
      return {
        color: 'white',
        background: 'var(--gray700)',
      }
  }
}

export const getColorBySeverity = (severity: string) => {
  switch (severity) {
    case 'Urgent':
      return {
        color: 'var(--error-color)',
        background: 'var(--red100)',
      }
    case 'Cosmetic':
      return {
        color: '#3C2CA4',
        background: '#F1F0FF',
      }
    case 'High':
      return {
        color: '#ff9900',
        background: 'var(--red100)',
      }
    case 'Medium':
      return {
        color: '#CC9300',
        background: '#FFFBF0',
      }
    default:
      return {
        color: '#CC9300',
        background: '#FFFBF0',
      }
  }
}

export const getTradeLevelInfo = (tradeLevel: number) => {
  switch (tradeLevel) {
    case 1:
      return {
        color: '#000',
        text: 'Not confirmed - User Declared',
      }
    case 2:
      return {
        color: theme.palette.red[700],
        text: 'light',
      }
    case 3:
      return {
        color: theme.palette.orange[700],
        text: 'Competent',
      }
    case 4:
      return {
        color: theme.palette.green[700],
        text: 'Skilled',
      }
    case 5:
      return {
        color: theme.palette.blue[700],
        text: 'Licensed',
      }
    default:
      return {
        color: '#000',
        text: 'Not confirmed - User Declared',
      }
  }
}

export const getColorByJobStatus = (status: string) => {
  switch (status?.toUpperCase()) {
    case 'Completed':
      return {
        color: 'var(--green800)',
      }
    case 'Approved':
      return {
        color: 'var(--blue700)',
      }
    case 'Released':
      return {
        color: 'var(--navy-700)',
      }
    default:
      return {
        color: 'var(--navy-700)',
      }
  }
}

export const getColorByOrderItemStatus = (status: string) => {
  switch (status?.toUpperCase()) {
    case ORDER_ITEM_STATUS.ACCEPTED:
      return {
        color: 'var(--green800)',
      }
    case ORDER_ITEM_STATUS.REJECTED:
      return {
        color: 'var(--error-color)',
      }
    default:
      return {
        color: 'var(--navy-700)',
      }
  }
}

export const getColorForDispatchStatus = (status: string) => {
  switch (status?.toUpperCase()) {
    case DISPATCH_STATUS.ASSIGNED:
    case DISPATCH_STATUS.PENDING:
      return {
        color: 'var(--blue700)',
        background: 'var(--blue100)',
      }
    case DISPATCH_STATUS.ACCEPTED:
      return {
        color: 'var(--green900)',
        background: 'var(--green100)',
      }
    case DISPATCH_STATUS.REVOKED:
      return {
        color: 'var(--red700)',
        background: 'var(--red100)',
      }
    case ORDER_ITEM_STATUS.REJECTED:
      return {
        color: 'var(--red800)',
        background: 'var(--red100)',
      }
    default:
      return {
        color: 'var(--blue700)',
        background: 'var(--blue100)',
      }
  }
}

export const groupByWithTitle = (array: any[], key: string): GroupedItem[] => {
  return Object.values(
    array.reduce((acc: any, el: any) => {
      acc[el[key]] = acc[el[key]] || { title: el[key], items: [] }
      acc[el[key]].items.push(el)
      return acc
    }, [])
  )
}

export const groupBy = (array: any[], key: string) => {
  return array.reduce((result: any, currentValue: any) => {
    ; (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    )
    return result
  }, {})
}

function hasOrderId(itemsByCategory: GroupedItem[], orderId: string) {
  for (const category of itemsByCategory) {
    for (const item of category.items) {
      if (item.orderId === orderId) {
        return true
      }
    }
  }
  return false
}

export const renderWorkOrdersTabs = (
  order: OrderType,
  items: GroupedItem[]
) => {
  const hasItems = hasOrderId(items, order?.orderId || '')
  const data = {
    title: order.orderName,
    color: 'inherit',
    icon: '',
    fullHeader: true,
  }
  switch (order.statusType) {
    case ORDER_STATUS.REJECTED:
      data.title = 'Rejected Orders'
      data.color = 'var(--error-color)'
      data.icon = 'DoDisturbAlt'
      data.fullHeader = false
      break
    case ORDER_STATUS.COMPLETE:
    case ORDER_STATUS.PAID:
      data.color = 'var(--green900)'
      data.icon = 'CheckCircleRounded'
      break
    case ORDER_STATUS.REVIEW:
      data.color = 'var(--orange700)'
      data.icon = 'WarningAmber'
      break
    case ORDER_STATUS.DISPATCHED:
    case ORDER_STATUS.IN_PROGRESS:
      data.icon = 'BuildRounded'
      break
    case ORDER_STATUS.APPROVED:
    case ORDER_STATUS.PENDING:
      data.icon = 'QueryBuilderRounded'
      break
    case ORDER_STATUS.ON_HOLD:
      data.icon = 'Pause'
      break
    default:
      break
  }
  if (!hasItems && order.statusType !== ORDER_STATUS.ON_HOLD) {
    data.icon = 'RemoveCircleOutlineRounded'
  }
  return data
}

export const headerCSVParser = (headers: HeaderType[]) => {
  return headers.reduce((acc: any, curr: any) => {
    acc = [
      ...acc,
      {
        label: typeof curr.label === 'object' ? curr.labelCSV : curr.label,
        key: curr.id,
      },
    ]
    return acc
  }, [])
}

export const reduceRecipientsForMessage = (
  users: any,
  currentUserID: string
) => {
  if (!users) return []
  return users.reduce((acc: any, curr: any) => {
    if (curr.id !== currentUserID) {
      acc = [
        ...acc,
        {
          id: curr.id,
          firstName: curr.firstName,
          lastName: curr.lastName,
          email: curr.email,
          phone: curr.phone,
          fullName: curr.fullName,
        },
      ]
    }
    return acc
  }, [])
}

export const deleteRepeatedById = (array: any[]) => {
  return array?.reduce((acc: any, curr: any) => {
    if (acc?.find((item: any) => item?.id === curr?.id)) {
      return acc
    }
    return [...acc, curr]
  }, [])
}

export const getMessageContacts = (contacts: ContactsMessage[]) => {
  return contacts.reduce((acc: any, curr: RecipientMessage) => {
    const { type, ...contact } = curr
    if (curr?.type) {
      acc[curr?.type] = acc[curr?.type]
        ? [...acc[curr?.type], contact]
        : [contact]
    } else {
      acc = [...acc, contact]
    }
    return acc
  }, {})
}

export const getUniqueMessages = (messages: Message[]) => {
  return messages.reduce((acc: any, curr: any) => {
    if (acc.find((item: any) => item.threadId === curr.threadId)) {
      return acc
    }
    return [...acc, curr]
  }, [])
}

export const isSameDayTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const today = new Date()
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  )
}

export const isYesterdayTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  return (
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate()
  )
}

export const isTomorrowTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  return (
    date.getFullYear() === tomorrow.getFullYear() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getDate() === tomorrow.getDate()
  )
}

export const isYesterdayOrBeforeTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  return (
    date.getFullYear() < yesterday.getFullYear() ||
    (date.getFullYear() === yesterday.getFullYear() &&
      date.getMonth() < yesterday.getMonth()) ||
    (date.getFullYear() === yesterday.getFullYear() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getDate() <= yesterday.getDate())
  )
}

export const isTodayColor = (
  timestamp: number | null | undefined,
  status: string
): string => {
  if (timestamp !== null && timestamp !== undefined) {
    if (isSameDayTimestamp(timestamp) && status !== 'COMPLETE') {
      // today
      return 'var(--orange100)'
    } else if (
      isYesterdayOrBeforeTimestamp(timestamp) &&
      status !== 'COMPLETE'
    ) {
      // yesterday
      return 'var(--red100)'
    } else if (isTomorrowTimestamp(timestamp) && status !== 'COMPLETE') {
      // tomorrow
      return 'var(--green100)'
    }
  } else {
    return 'var(--orange100)'
  }
  return 'var(--white-color)'
}

export const dayFromTimestamp = (
  timestamp: number | null | undefined
): string => {
  if (timestamp !== null && timestamp !== undefined) {
    const diffDays = daysFromNow(timestamp * 1000)

    if (isSameDayTimestamp(timestamp)) {
      return 'TODAY'
    } else if (isYesterdayTimestamp(timestamp)) {
      return 'YESTERDAY'
    } else if (isTomorrowTimestamp(timestamp)) {
      return 'TOMORROW'
    } else if (diffDays >= 2) {
      return Math.trunc(diffDays) + ' DAYS AGO'
    }
  } else {
    return 'TODAY'
  }
  return formatTimestamp(timestamp, 'MM/DD/YYYY')
}

export const statusTagColor = (status: string) => {
  switch (status?.toUpperCase()) {
    case REVIEW_STATUS.APPROVED:
      return {
        color: 'var(--green900)',
        background: 'var(--green100)',
      }
    case REVIEW_STATUS.DENIED:
      return {
        color: 'var(--red800)',
        background: 'var(--red100)',
      }
    case REVIEW_STATUS.EXPIRED:
      return {
        color: 'var(--red700)',
        background: 'var(--red100)',
      }
    case REVIEW_STATUS.REVIEWED:
    case REVIEW_STATUS.SUBMITTED:
      return {
        color: 'var(--blue700)',
        background: 'var(--blue100)',
      }
    case REVIEW_STATUS.DRAFT:
      return {
        color: 'var(--orange700)',
        background: 'var(--orange100)',
      }
    default:
      return {
        color: 'white',
        background: 'var(--gray700)',
      }
  }
}

export const expiracyStatusTagColor = (status: string) => {
  switch (status?.toUpperCase()) {
    case EXPIRACY_STATUS.EXPIRED:
      return {
        color: 'var(--red800)',
        //background: 'var(--red100)',
      }
    case EXPIRACY_STATUS.ALMOST_EXPIRED_1MONTH:
      return {
        //yellow
        color: 'var(--yellow800)',
        //background: 'var(--yellow100)',
      }
    case EXPIRACY_STATUS.ALMOST_EXPIRED_2WEEKS:
      return {
        color: 'var(--orange700)',
        //background: 'var(--orange100)',
      }
    case EXPIRACY_STATUS.ALMOST_EXPIRED_1WEEK:
      return {
        color: 'var(--orange700)',
        // background: 'var(--orange100)',
      }

    case EXPIRACY_STATUS.VALID:
      return {
        color: 'var(--green900)',
        background: 'var(--green100)',
      }
    default:
      return {
        color: 'white',
        //background: 'var(--gray700)',
      }
  }
}

export const expiracyStatusText = (status: string) => {
  switch (status?.toUpperCase()) {
    case EXPIRACY_STATUS.EXPIRED:
      return 'Expired'
    case EXPIRACY_STATUS.ALMOST_EXPIRED_1MONTH:
      return 'Expires in 1 month'
    case EXPIRACY_STATUS.ALMOST_EXPIRED_2WEEKS:
      return 'Expires in 2 weeks'
    case EXPIRACY_STATUS.ALMOST_EXPIRED_1WEEK:
      return 'Expires in 1 week'

    case EXPIRACY_STATUS.VALID:
      return 'Valid'
    default:
      return 'Expired'
  }
}

export const getExpiracyStatus = (validToDate: number) => {
  const currentDate = new Date()
  const validToDateDate = new Date(validToDate * 1000) // Convert Unix timestamp to milliseconds

  const timeDifference = validToDateDate.getTime() - currentDate.getTime()

  if (timeDifference <= 0) {
    return EXPIRACY_STATUS.EXPIRED
  } else if (timeDifference <= 7 * 24 * 60 * 60 * 1000) {
    return EXPIRACY_STATUS.ALMOST_EXPIRED_1WEEK
  } else if (timeDifference <= 14 * 24 * 60 * 60 * 1000) {
    return EXPIRACY_STATUS.ALMOST_EXPIRED_2WEEKS
  } else if (timeDifference <= 30 * 24 * 60 * 60 * 1000) {
    return EXPIRACY_STATUS.ALMOST_EXPIRED_1MONTH
  } else {
    return EXPIRACY_STATUS.VALID
  }
}

export const hasNonValidLicense = (licenseList: License[]) => {
  if (!licenseList || licenseList.length === 0) {
    return false // No licenses, consider it valid
  }

  return licenseList.some((license) => {
    const status = getExpiracyStatus(license.validToDate)
    return status !== EXPIRACY_STATUS.VALID
  })
}

export const getNonValidLicenses = (licenseList: License[] | undefined) => {
  if (!licenseList || licenseList.length === 0) {
    return [] // No licenses, consider it valid
  }

  return licenseList.filter((license) => {
    const status = getExpiracyStatus(license.validToDate)
    return status !== EXPIRACY_STATUS.VALID
  })
}

export const updateObjectByPath: any = ({
  obj,
  path,
  value,
}: {
  obj: any
  path: string
  value: any
}) => {
  const [head, ...rest] = path.split('/')
  return {
    ...obj,
    [head]: rest.length
      ? updateObjectByPath({ obj: obj[head], path: rest.join('/'), value })
      : value,
  }
}

export const getDifferenceBetweenObjects: any = (
  obj1: any,
  obj2: any,
  op?: 'add' | 'remove' | 'replace',
  pathToConcat?: string
) =>
  reduce(
    obj1,
    (result: any, value: any, key: any) => {
      const concatedPath = pathToConcat ? `${pathToConcat}/${key}` : key
      const valueHasChanged = !obj2 || !isEqual(value, obj2[key as any])
      if (valueHasChanged) {
        if (typeof value === 'object') {
          //if value is an object we call the function again recursively
          const lastPath =
            concatedPath.split('/')[concatedPath.split('/').length - 1]
          return getDifferenceBetweenObjects(
            obj1[lastPath],
            obj2[lastPath],
            op,
            concatedPath
          )
        } else {
          return result.concat({ op, path: `/${concatedPath}`, value: value })
        }
      }
      return result
    },
    []
  )

export const flattenedAddress = (place: any) => {
  const city = place.address_components.find((i: any) => {
    return i.types.includes('locality')
  })?.long_name
  const sublocality = place.address_components.find((i: any) => {
    return i.types.includes('sublocality')
  })?.long_name
  const state = place.address_components.find((i: any) =>
    i.types.includes('administrative_area_level_1')
  )?.short_name
  const zipCode = place.address_components.find((i: any) =>
    i.types.includes('postal_code')
  )?.long_name
  const street_number = place.address_components.find((i: any) =>
    i.types.includes('street_number')
  )?.long_name
  const line_1 = place.address_components.find((i: any) =>
    i.types.includes('route')
  )?.long_name
  const administrative_area_level_2 = place.address_components.find((i: any) =>
    i.types.includes('administrative_area_level_2')
  )?.long_name
  const country = place.address_components.find((i: any) =>
    i.types.includes('country')
  )?.long_name
  return {
    city: city || sublocality,
    state,
    zipCode,
    line_1,
    street_number,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    formatted_address: place.formatted_address,
    county: administrative_area_level_2,
    country,
  }
}

export const ALL_AFFILIATE_TYPE_OPTIONS: SelectInputOption[] = [
  { key: 'ALL', label: 'All channels' },
  { key: 'BROKER', label: 'BROKER' },
  { key: 'INSTITUTIONAL', label: 'INSTITUTIONAL' },
]

export const ALL_WORK_ORDER_STATUS_OPTIONS: SelectInputOption[] = [
  {
    key: 'ALL',
    label: 'All work order status',
  },
  {
    key: 'RECALL',
    label: 'Recall',
  },
  {
    key: 'WARRANTY',
    label: 'Warranty',
  },
]

const enumArray = enumToArrayObject(AFFILIATE_TYPE)

export const AFFILIATE_TYPE_OPTIONS: SelectInputOption[] = getOptionsFromArray(
  enumArray,
  { key: 'key', label: 'label' }
)

export const sortItemsByCategories = (items: any): any => {
  const sortBy = Object.keys(ITEM_CATEGORY)
  const res = Object.entries(items).sort(
    (a: any, b: any) => sortBy.indexOf(a[0]) - sortBy.indexOf(b[0])
  )
  return Object.fromEntries(res)
}

export const isRoundPercentageOf = (amount: number, total: number): number => {
  return Math.round((amount / total) * 100)
}

export const getEmailComponents = (email: string) => {
  if (email && email.indexOf('<') > 0) {
    var comps = email.split('<')
    var name = (comps[0] + '').trim()
    var address = (comps[1].replace('>', '') + '').trim()
    if (name === address) {
      // Name is the email
      return {
        name: address.toLowerCase(),
        email: address.toLowerCase(),
      }
    } else {
      return {
        name: name,
        email: address.toLowerCase(),
      }
    }
  } else if (email) {
    return {
      name: email.trim().toLowerCase(),
      email: email.trim().toLowerCase(),
    }
  }
}

export const uniqueRecipients = (addresses: string) => {
  const recipients = addresses
    .split(/[,;]+/)
    .filter((str) => /\S/.test(str))
    .map((email) => getEmailComponents(email))

  return recipients
}

export const SHARE_ROLE = [
  {
    role: 'HOME_SELLER',
    label: 'Seller',
  },
  {
    role: 'HOME_BUYER',
    label: 'Buyer',
  },
  {
    role: 'LISTING_AGENT',
    label: 'Listing Agent',
  },
  {
    role: 'BUYERS_AGENT',
    label: "Buyer's Agent",
  },
  {
    role: 'BUYERS_CLOSING_COORDINATOR',
    label: "Buyer's Closing Coordinator",
  },
  {
    role: 'SELLERS_CLOSING_COORDINATOR',
    label: "Sellers' Closing Coordinator",
  },
  {
    role: 'OTHER',
    label: 'Other',
  },
]

export enum ITEM_CATEGORY_FOR_FILTERS {
  ELECTRICAL = 'Electrical',
  PLUMBING = 'Plumbing',
  HVAC = 'HVAC',
  WINDOWS_DOORS = 'Windows/Doors',
  MISCELLANEOUS = 'Miscellaneous',
  CL100 = 'CL100',
  CRAWLSPACE = 'Crawlspace',
  EXTERIOR = 'Exterior',
  INTERIOR = 'Interior',
  ATTIC = 'Attic',
  ROOF = 'Roof',
  UNCATEGORIZED = 'UNCATEGORIZED',
  EVALUATE = 'EVALUATE',
  EXCLUDED = 'Excluded',
  PERMITS_AND_FEES = 'Permits and Fees',
  ALL = 'All',
}

export function calcWeekends(startDate: Date, endDate: Date) {
  if (startDate > endDate) return []
  var date = startDate
  var dates = []

  while (date < endDate) {
    if (isWeekend(date)) dates.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return dates
}

export function getNextBusinessDayIfFriday(): Date | null {
  const today = new Date()
  const dayOfWeek = today.getDay()

  // In JavaScript, Friday is represented by 5
  if (dayOfWeek === 5) {
    // Add three days to the current date to get to Monday
    today.setDate(today.getDate() + 3)
    return today
  }

  return null
}

export const getHolidaysDate = () => {
  const holidays = [
    {
      date: '2024-01-01 00:00:00',
      name: "New Year's Day",
    },
    {
      date: '2024-01-15 00:00:00',
      name: 'Martin Luther King Jr. Day',
    },
    {
      date: '2024-02-19 00:00:00',
      name: "Washington's Birthday",
    },
    {
      date: '2024-05-27 00:00:00',
      name: 'Memorial Day',
    },
    {
      date: '2024-07-04 00:00:00',
      name: 'Independence Day',
    },
    {
      date: '2024-09-02 00:00:00',
      name: 'Labor Day',
    },
    {
      date: '2024-11-28 00:00:00',
      name: 'Thanksgiving Day',
    },
    {
      date: '2024-11-29 00:00:00',
      name: 'Day after Thanksgiving Day',
    },
    {
      date: '2024-12-24 00:00:00',
      name: 'Christmas Eve',
    },
    {
      date: '2024-12-25 00:00:00',
      name: 'Christmas Day',
    },
  ]
  return holidays.map((i) => new Date(i.date))
}

export const companyRoles = [
  CONTACT_ROLE.LISTING_AGENT,
  CONTACT_ROLE.BUYERS_AGENT,
  CONTACT_ROLE.BUYERS_CLOSING_COORDINATOR,
  CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR,
  CONTACT_ROLE.INSPECTOR,
  CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
]

export const homeownerRoles = [
  CONTACT_ROLE.HOMEOWNER,
  CONTACT_ROLE.HOME_BUYER,
  CONTACT_ROLE.HOME_SELLER,
  CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
  CONTACT_ROLE.AGENT_HOMEOWNER,
]
