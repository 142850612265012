import Delete from '@mui/icons-material/Delete'
import { Box, Grid, Typography } from '@mui/material'
import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader, TextFieldLabel } from 'src/components/UI'
import { itemsActions } from 'src/ducks/actions'
import { getOrderById } from 'src/ducks/selectors'
import { formatTimestamp, ORDER_STATUS } from 'src/helpers'
import { WorkOrdersContext } from '../../../../../context'
import { workOdersTypes } from '../../../../../context/types'
import styles from '../../styles.module.scss'
import { useParams } from 'react-router-dom'


const Notes = () => {
  const { state: { item, selectedOrderId, selectedItemId }, dispatch: dispatchContext } = useContext(WorkOrdersContext)
  const [newItem, setNewItem] = useState(item)
  const order = useSelector(getOrderById(selectedOrderId as string))
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { id } = useParams()

  const dispatch = useDispatch()

  const {
    completedTimestamp, vendorPaymentTimestamp,
    statusType, csmAcceptanceTimestamp,
  } = order

  const showPaid = statusType && (ORDER_STATUS.PAID === statusType || vendorPaymentTimestamp != null)
  const showAccepted = statusType && (ORDER_STATUS.COMPLETE === statusType || showPaid || csmAcceptanceTimestamp != null)
  const showComplete = statusType && (ORDER_STATUS.REVIEW === statusType || showAccepted || completedTimestamp != null)

  const vendorIsPaid = order.vendorPaymentTimestamp !== null ? true : (showAccepted && showComplete) ? true : false

  const changeItemValue = useCallback(
    (newValue, attr) => {
      dispatchContext({ type: 'SET_ITEM_VALUE', payload: { attr: attr, value: newValue } })
      dispatchContext({ type: workOdersTypes.SET_EDIT_ITEM_VALUE, payload: { attr: attr, value: newValue } })
    },
    [dispatchContext]
  );

  const onItemValueChanged = useMemo(
    () => debounce(changeItemValue, 300),
    [changeItemValue]
  );
  const fetchItems = () => {
    dispatch(itemsActions.fetchItemsByCategory({
      params: { jobId: id }
    }, (_succ) => {
      //setTableLoading(false)
    }))
  }


  const handleDeleteFile = (followUpNoteIndex: number, fileIndex: number) => {
    setDeleteLoading(true)
    const request = [{
      op: 'remove' as "remove",
      path: `/followupNotes/${followUpNoteIndex}/imageFiles/${fileIndex}`
    }]
    dispatch(itemsActions.updateItemByPath({ itemId: selectedItemId, request }, (success) => {
      if (success) {
        setDeleteLoading(false)
        // setFiles(files.filter(f => f.fileUrl !== file.fileUrl))
        fetchItems()
      }
    }))
  }

  useEffect(() => {
    setNewItem(item)
  }, [item])

  const followUpNotes = newItem.followupNotes;
  const followUpNotesDesc = followUpNotes?.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());

  return (
    <Grid>
      <Grid item xs={12} marginBottom={2}>
        <TextFieldLabel
          label='BossCat Notes for Technician:'
          placeholder='**Notes FOR Field Technician**'
          value={newItem.punchlistTechNote || ''}
          size="small"
          onChange={(e) => {
            setNewItem({ ...newItem, punchlistTechNote: e.target.value })
            onItemValueChanged(e.target.value, "punchlistTechNote");
          }}
          multiline
          rows={2}
          labelVariant='h6'
        // disabled={vendorIsPaid}
        />
      </Grid>
      <Grid item xs={12} marginBottom={2}>
        <TextFieldLabel
          label='Field Tech Notes:'
          placeholder='**Notes FROM Field Technician**'
          value={newItem.fieldNote || ''}
          size="small"
          onChange={(e) => {
            setNewItem({ ...newItem, fieldNote: e.target.value })
            onItemValueChanged(e.target.value, "fieldNote");
          }}
          multiline
          rows={2}
          labelVariant='h6'
        // disabled={vendorIsPaid}
        />
      </Grid>
      <Grid item xs={12}>
        <Box className={styles.rejectedTableItem}>
          <Box>
            <Typography variant='h6'>Rejected History:</Typography>
          </Box>
        </Box>
        {followUpNotesDesc?.map((note, index) => {
          return (
            <Box className={styles.rejectedTableItem}>
              <Box className={styles.rejectedTableItemText}>
                <Typography>{note.note}</Typography>
              </Box>
              <Box className={styles.rejectedTableItemText2}>
                <Typography>{formatTimestamp(note.createdOn, "MM/DD/YYYY HH:mm:ss")}</Typography>
              </Box>
              {note.imageFiles.length > 0 && <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }} className={styles.rejectedTableItemText2}>
                {note.imageFiles.map((file, imageIndex) => <div style={{ position: 'relative', display: 'block' }}>
                  <a href={file.fileUrl} target='_blank' rel='noreferrer'>
                    <img src={file.fileUrl} alt={file.name} style={{ width: '50px', height: '50px', objectFit: 'contain', borderRadius: '8px' }} />
                  </a>
                  <Box onClick={() => deleteLoading ? null : handleDeleteFile(index, imageIndex)} style={{ position: 'absolute', top: 0, right: 0, zIndex: 1, cursor: 'pointer' }}>
                    {deleteLoading ? <Loader size={12} margin='0' /> : <Delete color='error' />}
                  </Box>
                </div>)}
              </Box>}
            </Box>
          )
        })}
      </Grid>
    </Grid >
  )
}
export default Notes