import { Box, Button, Checkbox, Grid, Typography } from '@mui/material'
import { useContext, useEffect, FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { getItemImages, getItemsSortedByCategory } from 'src/ducks/selectors'
import { dateFormatString, formatTimestampDate, getColorBySeverity, ITEM_STATUS } from 'src/helpers'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { itemsActions } from 'src/ducks/actions'
import styles from './styles.module.scss'
import DropDownSelect from './DropDownSelect'
import { WorkOrdersContext } from './context'
import Header from './Header'
import { OverlayLoader, PrivateLoader } from 'src/components/templates'
import { GroupedItem, ImageFile } from 'src/ducks/types'
import { computer, seeAll } from 'src/assets'
import theme from 'src/components/UI/MaterialUI/theme'
import OrderItemsModal from './Modals'

const ReviewItems = () => {
  const fullHeader = false
  const { id } = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const {
    dispatch: dispatchContext,
    state: { workItems }
  } = useContext(WorkOrdersContext)

  const ImagePreview: FC<any> = (selectedItemId: any) => {
    const images: ImageFile[] = useSelector(getItemImages(selectedItemId?.selectedItemId ?? ''))
    const before = images.filter(image => !image.uploadedAfterJob)
    const after = images.filter(image => image.uploadedAfterJob)

    const sliceBefore = before.length > 4 ? 4 : before.length
    const sliceAfter = after.length > 4 ? 4 : after.length

    return (
      <Grid container spacing={1} justifyContent='flex-start' sx={{ padding: '0px 10px', marginTop: '10px' }}>
        {before.length > 0 && <Grid container item width='fit-content' sx={{ backgroundColor: '#F0F3FA', borderRadius: '12px', marginRight: '10px', paddingRight: '10px' }}>
          <Grid item xs={12} width='0px'>
            <Typography fontWeight='bold' fontSize='10px'>
              {'Before (' + before.length + ' images)'}
            </Typography>
          </Grid>
          {before.slice(0, sliceBefore).map((image, index) => {
            return (
              <Grid key={index} item width='48px' margin='4px'>
                <img
                  alt='' src={image.fileUrl}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '8px',
                    height: '48px',
                    width: '48px'
                  }}
                />
              </Grid>
            )
          })}
          <Grid item width='48px' margin='4px'>
            <img
              alt='' src={seeAll}
              style={{
                objectFit: 'cover',
                borderRadius: '8px',
                cursor: 'pointer',
                height: '48px',
                width: '48px'
              }}
              onClick={() => {
                dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: selectedItemId?.selectedItemId })
                dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'ALL_IMAGES' })
                dispatchContext({ type: 'SET_TAB_SELECTED', payload: 0 })
              }}
            />
          </Grid>
        </Grid>}

        {after.length > 0 && <Grid container item width='fit-content' sx={{ backgroundColor: '#EDFDF6', borderRadius: '12px', paddingRight: '10px' }}>
          <Grid item xs={12} width='0px' marginRight='8px'>
            <Typography fontWeight='bold' fontSize='10px' color='#0E905A' minWidth='100px'>
              {'After (' + after.length + ' images)'}
            </Typography>
          </Grid>
          {after.slice(0, sliceAfter).map((image, index) => {
            return (
              <Grid key={index} item width='48px' margin='4px'>
                <img
                  alt='' src={image.fileUrl}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '8px',
                    height: '48px',
                    width: '48px'
                  }}
                />
              </Grid>
            )
          })}
          <Grid item width='48px' margin='4px'>
            <img
              alt='' src={seeAll}
              style={{
                objectFit: 'cover',
                borderRadius: '8px',
                cursor: 'pointer',
                height: '48px',
                width: '48px'
              }}
              onClick={() => {
                dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: selectedItemId?.selectedItemId })
                dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'ALL_IMAGES' })
                dispatchContext({ type: 'SET_TAB_SELECTED', payload: 1 })
              }}
            />
          </Grid>
        </Grid>}
      </Grid>
    )
  }

  const headers = [
    {
      id: 'checkbox',
      custom: true,
      Element: (row: any, index: number): JSX.Element => {
        return (
          <Checkbox
            key={index}
            checked={row.checked || false}
            onClick={() => {
              dispatchContext({
                type: 'SET_REVIEW_ITEMS',
                payload: [...workItems.map((item: any) => {
                  item.items.forEach((subitem: any) => {
                    if (subitem.itemId === row.itemId) {
                      subitem.checked = !subitem.checked
                    }
                    return subitem
                  })
                  return item
                })]
              })
            }}
            color='infoText'
          />
        )
      },
      width: 20
    },
    {
      id: 'inspectionReportReference',
      label: 'Ref #',
      width: 30 // width: 100
    },
    {
      id: 'item',
      label: 'Item',
      custom: true,
      Element: (row: any): JSX.Element => {
        const { background } = getColorBySeverity(row.severity)

        return (
          <Box marginRight={3}>
            <Box display='flex' flexDirection='column' gap={0.5}>
              <Box display='flex' gap={1}>
                <Typography variant='h6' color='var(--blue700)' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  {row.title}
                </Typography>
                <Tag
                  sx={{ paddingX: 2, textTransform: 'uppercase', height: '25px' }}
                  variant='caption'
                  width='fit-content'
                  background={background}
                  color='var(--black-color)'
                >
                  {row.severity}
                </Tag>

                {row.status === ITEM_STATUS.REJECTED && (
                  <Typography color='var(--red600)' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    {`(Client Rejected ${dateFormatString(formatTimestampDate(row.rejectedOn), 'MM-DD-YYYY hh:mm:ss')})`}
                  </Typography>
                )}
              </Box>
              <Typography variant='body1Bold'>{row.disclaimer}</Typography>
              {row.inspectionReportNote && <Typography><Typography variant='body1Bold'>Inspection Notes: </Typography>{row.inspectionReportNote}</Typography>}
              {row.requestedRepairNote && <Typography><Typography variant='body1Bold'>Requested Repair: </Typography>{row.requestedRepairNote}</Typography>}
              {row.fieldNote && <Typography><Typography variant='body1Bold' color='var(--orange500)'>Field Tech Notes: </Typography>{row.fieldNote}</Typography>}
              <ImagePreview selectedItemId={row.itemId} />
            </Box>
          </Box>
        )
      }
    },
    { id: 'quantity', label: 'Qty', width: 30 },
    {
      id: 'totalCost',
      label: 'Vendor Price',
      custom: true,
      maxWidth: 80,
      Element: (row: any): JSX.Element => {
        return (
          <Box marginRight={3}>
            <Box display='flex' flexDirection='column' gap={0.5}>
              <Typography variant='body1Bold'>${row.totalCost}</Typography>
              <Typography fontSize='8px' fontStyle='italic'>{`($ ${row.unitCost})`}</Typography>
            </Box>
          </Box>
        )
      }
    },
    {
      id: 'customerCost',
      label: 'Customer Price',
      custom: true,
      width: 190,
      Element: (row: any): JSX.Element => {
        return (
          <Box marginRight={1}>
            <Box display='flex' flexDirection='column' gap={0.5} marginBottom={2}>
              <Typography variant='body1Bold'>${row.totalPrice}</Typography>
              <Typography fontSize='8px' fontStyle='italic'>{`($ ${row.unitPrice})`}</Typography>
              <Typography fontSize='10px' variant='body1Bold'>Uploaded Date: </Typography>
              <Typography fontSize='10px'>{dateFormatString(formatTimestampDate(row.completedOn), 'MM-DD-YYYY hh:mm:ss')}</Typography>
              <Typography fontSize='10px' variant='body1Bold'>Uploaded By: </Typography>
              <Typography fontSize='10px'>{row.completedByName}</Typography>
            </Box>

            <Box display='flex' sx={{ gap: '8px' }}>
              <Button
                variant='contained'
                sx={{
                  width: '120px',
                  backgroundColor: theme.palette.green[700],
                  borderColor: theme.palette.green[700],
                  '&:hover': {
                    backgroundColor: theme.palette.green[700],
                    borderColor: theme.palette.green[700]
                  }
                }}
                onClick={() => { approveReviewItem(row) }}
              >
                QC Accept
              </Button>
              <Button
                variant='contained'
                sx={{
                  width: '120px',
                  backgroundColor: theme.palette.red[600],
                  borderColor: theme.palette.red[600],
                  '&:hover': {
                    backgroundColor: theme.palette.red[600],
                    borderColor: theme.palette.red[600]
                  }
                }}
                onClick={() => { rejectReviewItem(row) }}
              >
                QC Reject
              </Button>
            </Box>
          </Box>
        )
      }
    }
  ]

  const ReviewItemsFirst = () => {
    const itemsByCategory = useSelector(getItemsSortedByCategory())
    const {
      state: { workItems, checkAll, expandAll }
    } = useContext(WorkOrdersContext)

    return (
      <Box height='100%' margin='10px'>
        <Header hasItems={itemsByCategory.length > 0} fullHeader={fullHeader} />
        <OverlayLoader loading={false}>
          <Box marginTop={2} height='100%'>
            {workItems.length
              ? <>
                {workItems.map((workItem: GroupedItem, index: number) => {
                  return (
                    <DropDownSelect
                      workItem={workItem}
                      key={index}
                      index={index}
                      initiallyExpanded={expandAll}
                      initiallyChecked={checkAll}
                      checkAll={handleCheckAllWorkItems}
                    >
                      <Table
                        hasPagination={false}
                        headers={headers}
                        className={styles.Table}
                        small
                        hasBorders={false}
                      >
                        {workItem.items}
                      </Table>
                    </DropDownSelect>
                  )
                })}
              </>
              : <Grid container direction='column' justifyContent='center' alignItems='center' padding={12} height='100%'>
                <img src={computer} alt='computer' />
                <Typography marginTop={2} variant='h6Bold' align='center'>No items added to <br /> this order yet</Typography>
              </Grid>}

          </Box>
        </OverlayLoader>
      </Box>
    )
  }

  const TABS = [
    {
      label: '',
      content: <ReviewItemsFirst />,
      className: styles.All,
      Element: (<Box display='flex' height='20px' />)
    }
  ]

  const handleCheckAllWorkItems = (index?: number, checked?: boolean) => {
    dispatchContext({
      type: 'SET_REVIEW_ITEMS',
      payload: workItems.map((item: any, i: number) => {
        if (i === index) {
          item.items.forEach((subitem: any) => {
            subitem.checked = !checked
            return subitem
          })
          return item
        }
        return item
      })
    })
  }

  const approveReviewItem = (item: any) => {
    setLoading(true)
    dispatch(itemsActions.updateItem({
      itemId: item.itemId, partialItem: { status: ITEM_STATUS.ACCEPTED }
    }, (succ) => {
      dispatch(itemsActions.fetchItemsByCategory({
        params: { jobId: id, status: ITEM_STATUS.COMPLETE }
      }, (succ, items) => {

        dispatchContext({ type: 'SET_REVIEW_ITEMS', payload: items })
        dispatchContext({ type: 'REFRESH', payload: true })
        setLoading(false)
      }))
    }))
  }

  const rejectReviewItem = (item: any) => {
    dispatchContext({
      type: 'SET_REVIEW_ITEMS',
      payload: [...workItems.map((item: any) => {
        item.items.forEach((subitem: any) => {
          if (subitem.itemId === item.itemId) {
            subitem.checked = !subitem.checked
          }
          return subitem
        })
        return item
      })]
    })
    dispatchContext({ type: 'SET_SELECTED_ITEMS_IDS', payload: [item.itemId] })
    dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: item.itemId })
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
    dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'DELETE_ONE' })
  }

  useEffect(() => {
    dispatch(itemsActions.fetchItemsByCategory({
      params: { jobId: id, status: ITEM_STATUS.COMPLETE }
    }, (succ, items) => {
      dispatchContext({ type: 'SET_REVIEW_ITEMS', payload: items })
      dispatchContext({ type: 'REFRESH', payload: false })
      setLoading(false)
    }))
  }, [dispatch, dispatchContext, id])

  return (
    <>
      <OrderItemsModal />
      <PrivateLoader loading={loading}>
        <Box>
          <TabNavigation
            tabs={TABS}
            variant='outlined'
            margin='1rem 0 0'
            showExpand
            className={styles.Tabs}
          />
        </Box>
      </PrivateLoader>
    </>
  )
}

export default ReviewItems
