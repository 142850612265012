import { isEmpty } from 'lodash'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import theme from 'src/components/UI/MaterialUI/theme'
import { clientActions } from 'src/ducks/actions'
import { employeeIsAdmin, getClient, getEmployeeRoles } from 'src/ducks/selectors'
import { Client } from 'src/ducks/types'
import { EMPLOYEE_ROLE_TYPE, formatTimestamp, PHONE_MASK_INPUT, PHONE_MASK_REGEXP } from 'src/helpers'
import { Box, Button, EditablePanel, Grid, Stats, TextFieldLabel, Typography } from '../../..'
import Modal from '../../molecules/Modal'
import { ModalActionType } from '../../molecules/Modal/types'
import { ProfileInfoProps } from './types'

import { Address as ClientAddress } from 'src/ducks/types'
import SelectAddress from '../SelectAddress'
import { Address } from '../SelectAddress/types'

const ProfileInfo: FC<ProfileInfoProps> = ({
  userData,
  userStats,
  narData = {},
  openModal
}) => {
  const dispatch = useDispatch()
  const client = useSelector(getClient())

  const [open, setOpen] = useState(false)
  const [newContact, setNewContact] = useState(client)
  const [showWarnings, setShowWarnings] = useState(false)
  const [address, setAddress] = useState<any>('')
  const [openAddress, setOpenAddress] = useState(false)

  const isAdmin = useSelector(employeeIsAdmin)
  const employeeRoles = useSelector(getEmployeeRoles)
  const employeeIsManager = employeeRoles.some((role) => role.roleType === EMPLOYEE_ROLE_TYPE.MANAGER)

  const { firstName, lastName, phone } = newContact

  const validate =
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    ((PHONE_MASK_REGEXP.test(phone?.toString()) && !isEmpty(phone)) || isEmpty(phone))

  const isValid: boolean =
    !isEmpty(address?.line_1) &&
    address?.line_1 !== ' ' &&
    !!address?.zipCode &&
    address?.latitude &&
    address?.longitude

  const handleClose = () => {
    setOpen(false)
    setShowWarnings(false)
    setNewContact(client)
  }

  useEffect(() => {
    setNewContact(client)
  }, [client])

  const handleUpdateClient = (
    contact: Partial<Client>,
  ) => {
    if (!client.id || isEmpty(contact)) return;
    dispatch(
      clientActions.updateClientId(client.id, contact, (succ: boolean) => {
        setOpen(false)
        if (succ) {
          toast.success("Customer was updated successfully")
        } else {
          setNewContact(client)
        }
        // fetchClient()
      })
    )
  }

  const getChangedContactFields = (
    contact: Partial<Client>,
    newContact: Partial<Client>
  ) => {
    const edited = { ...newContact }
    if (edited.phone && typeof edited.phone === "string") {
      edited.phone = Number(edited.phone.replaceAll('-', ''))
    }
    for (const attr in newContact) {
      if (
        contact &&
        newContact[attr as keyof Client] ===
        contact[attr as keyof Client]
      ) {
        delete edited[attr as keyof Client]
      }
    }

    return edited
  }

  const handleChange = (newAddress: Address) => {

    setAddress(newAddress)
  }

  const handleCloseAddress = () => {
    setOpenAddress(false)
    setAddress('')
  }

  const handleSaveEditAddress = () => {
    if (isValid) {
      var editAdress: ClientAddress = {
        city: address.city,
        state: address.state,
        line_1: address.line_1,
        line_2: address.line_2,
        zipCode: address.zipCode,
        latitude: address.latitude,
        longitude: address.longitude,
        fullAddress: '',
      }
      handleUpdateClient(
        getChangedContactFields(client, { address: editAdress })
      )

      setOpenAddress(false)
      setAddress('')
    }
  }

  const modalActionAddress: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => {
        handleCloseAddress()
      },
    },
    {
      textButton: 'Update Address',
      variant: 'contained',
      onClick: () => {
        handleSaveEditAddress()
      },
      icon: 'Save',
      iconColor: 'white',
      disabled: !isValid,
    },
  ]



  const handleAdd = () => {
    setOpen(false)

    client.fullName = `${firstName} ${lastName}`

    setNewContact({ ...newContact })

    handleUpdateClient(
      getChangedContactFields(client, newContact)
    )
    // setNewContact(newContactDefault)
  }

  const modalAction: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => {
        handleClose()
      },
    },
    {
      textButton: 'Update Contact',
      variant: 'contained',
      onClick: () => {
        if (validate) {
          handleAdd()
        } else {
          setShowWarnings(true)
        }
      },
      icon: 'Save',
      iconColor: 'white',
    },
  ]

  const handlePhone = (e: any) => {
    setNewContact({ ...newContact, phone: e.target.value })
  }
  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      width='25%'
    >
      {open && (
        <Modal
          setOpen={setOpen}
          open={open}
          title={'Edit Customer Information'}
          actions={modalAction}
          onClose={() => {
            handleClose()
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextFieldLabel
                name="add-job-contact-modal-n"
                id="add-job-contact-modal-n"
                type="text"
                labelVariant="h6"
                label="First Name:"
                value={firstName}
                error={showWarnings && isEmpty(firstName)}
                // className={styles.TextFieldLabel}
                onChange={(e) => {
                  setNewContact({ ...newContact, firstName: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel
                name="add-job-contact-modal-ln"
                id="add-job-contact-modal-ln"
                type="text"
                labelVariant="h6"
                label="Last Name:"
                value={lastName}
                error={showWarnings && isEmpty(lastName)}
                // className={styles.TextFieldLabel}
                onChange={(e) => {
                  setNewContact({ ...newContact, lastName: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel
                name="add-job-contact-modal-ph"
                id="add-job-contact-modal-ph"
                labelVariant="h6"
                label="Phone:"
                value={phone?.toString()}
                placeholder="876-543-1223"
                mask={PHONE_MASK_INPUT}
                error={
                  showWarnings &&
                  !isEmpty(phone) &&
                  !PHONE_MASK_REGEXP.test(phone?.toString())
                }
                // className={styles.TextFieldLabel}
                onChange={handlePhone}
              />
            </Grid>
          </Grid>
        </Modal>
      )}
      <Box>
        <Typography variant="h4" fontFamily="var(--font-medium)">
          {`Created: ${formatTimestamp(client.insertedOn, 'MM/DD/YYYY')}`}
        </Typography>
        <Typography marginTop="8px" variant="h4" fontFamily="var(--font-medium)">
          {`Registration Completed: ${client?.registrationCompleteOn ? formatTimestamp(client.registrationCompleteOn, 'MM/DD/YYYY') : "NO"}`}
        </Typography>
        <Grid container paddingTop="1.25rem" justifyContent="space-between">
          <Typography
            fontFamily="var(--font-medium)"
            fontSize='1.125rem'
          >
            Contact info:
          </Typography>
          <Button
            variant='containedLight'
            color='primary'
            sx={{ height: '30px' }}
            onClick={() => {
              setOpen(true)
            }}
          >
            Edit Info
            <Icon name="Edit" sx={{ marginLeft: '.5rem' }} />
          </Button>
          {/* <IconButton
            size="small"
            // disabled={true}
            className={styles.editButton}
            onClick={() => {
              setOpen(true)
            }}
          >
            Edit Info
            <EditIcon />
          </IconButton> */}

          {(isAdmin || employeeIsManager) && <Button
            variant='containedLight'
            color='primary'
            sx={{ height: '30px' }}
            onClick={() => {
              setOpenAddress(true)
            }}
            disabled={!isAdmin && !employeeIsManager}
          >
            Edit Address
            <Icon name="Edit" sx={{ marginLeft: '.5rem' }} />
          </Button>}
          {/* <IconButton
            size="small"
            // disabled={true}
            className={styles.editButton}
            onClick={() => {
              setOpenAddress(true)
            }}
          >
            Edit Address
            <EditIcon />
          </IconButton> */}
        </Grid>

        <Typography color='GrayText' fontSize='1rem' paddingTop='0.5rem'>
          Name:
        </Typography>
        <Typography fontSize='1rem' paddingTop='0.5rem'>
          {`${client.firstName} ${client.lastName}`}
        </Typography>
        <Typography color='GrayText' fontSize='1rem' paddingTop='0.5rem'>
          Phone:
        </Typography>
        <Typography fontSize='1rem' paddingTop='0.5rem'>
          {client.phone}
        </Typography>
        <Typography color='GrayText' fontSize='1rem' paddingTop='0.5rem'>
          Email:
        </Typography>
        <Typography fontSize='1rem' paddingTop='0.5rem'>
          {(client.email?.[0] as any)?.email}
        </Typography>
      </Box>
      <Box
        borderBottom={`2px solid ${theme.palette.grey[200]}`}
        height='0px'
        marginY='1rem'
        width='auto'
      />
      <Stats data={userStats} />
      <Box
        borderBottom={`2px solid ${theme.palette.grey[200]}`}
        height='0px'
        marginY='1rem'
        width='auto'
      />
      <EditablePanel data={narData} openModal={openModal} />

      <Box
        sx={{
          position: 'absolute',
          bottom: '12px',
          right: '12px',
        }}
      >
        {openAddress && (
          <Modal
            setOpen={setOpenAddress}
            open={openAddress}
            title={'Edit Address Information'}
            actions={modalActionAddress}
            onClose={() => {
              handleCloseAddress()
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectAddress
                  placeholder="Address"
                  showWarnings={false}
                  savedAddress={address}
                  onChange={handleChange}
                />
              </Grid>

            </Grid>
          </Modal>
        )}
      </Box>



    </Box >

  )
}

export default ProfileInfo
