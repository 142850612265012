export enum LOADER_TYPES {
  LINEAR = 'LINEAR',
  CIRCULAR = 'CIRCULAR'
}
export interface LoaderProps {
  type?: LOADER_TYPES
  margin?: string
  color?: string
  size?: number
}
