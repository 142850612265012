import { FC } from 'react'
import { icons } from 'src/assets'

export type IconNames =
  | 'Home'
  | 'Calendar'
  | 'Jobs'
  | 'Payments'
  | 'Promo Code'
  | 'Customers'
  | 'Reports'
  | 'Pros'
  | 'Site Visits'
  | 'Search'
  | 'Tasks'
  | 'Advanced'
  | 'Companies'
  | 'Estimates'
  | 'Subscriptions'
  | 'Admin'
  | 'Territories'
  | 'Repair Items'
  | 'Dashboard'
  | 'Homemart'
  | 'Jobs Main View'
  | 'Project Coordinator View'
  | 'Inspection\nCompanies'

export interface NavBarIconProps {
  icon?: IconNames
}

export const NavBarIcon: FC<NavBarIconProps> = ({ icon }) => {
  switch (icon) {
    case 'Home':
      return <icons.Home />
    case 'Calendar':
      return <icons.EventNote />
    case 'Jobs':
      return <icons.Build />
    case 'Payments':
      return <icons.Payment />
    case 'Promo Code':
      return <icons.Discount />
    case 'Customers':
      return <icons.People />
    case 'Reports':
      return (
        <icons.Equalizer
          sx={{
            color: '#0f102c',
            background: 'rgb(245, 249, 242)',
            fontSize: '1.1rem',
          }}
        />
      )
    case 'Pros':
      return <icons.Person />
    case 'Search':
      return <icons.Search />
    case 'Advanced':
      return <icons.Search />
    case 'Tasks':
      return <icons.CheckCircle />
    case 'Site Visits':
      return <icons.DirectionsWalk />
    case 'Companies':
      return <icons.AccountBalance />
    case 'Estimates':
      return <icons.Assignment />
    case 'Subscriptions':
      return <icons.Loyalty />
    case 'Admin':
      return <icons.AdminPanelSettings />
    case 'Territories':
      return <icons.LocationOn />
    case 'Repair Items':
      return <icons.MonetizationOn />
    case 'Homemart':
      return <icons.ShoppingCart />
    case 'Jobs Main View':
      return <icons.PersonSearch />
    case 'Project Coordinator View':
      return <icons.List />
    case 'Inspection\nCompanies':
      return <icons.ChromeReaderMode />
    default:
      return <icons.Home />
  }
}
