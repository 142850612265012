/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { Punchlist } from '../../api'
import { errorTextTryingTo } from '../../helpers'
import { promoCodesActions } from '../actions'
import { CreatePromoCodesAction, GetPromoCodesAction, promoCodesTypes, UpdatePromoCodesAction } from './types'


export function* getPromoCodes({
  payload,
  callback }: GetPromoCodesAction): SagaIterator {
  let success = false
  try {
    /*   const page = page ?? 0
     const size = sizes ?? 50
  
      const start = page * size
      const limit = start + size */

    const promoCodes = yield call(Punchlist.promoCodes.getPromoCodes, {
      params: payload
    })
    if (promoCodes) {
      yield put(promoCodesActions.setPromoCodes(promoCodes))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the promo codes'))
    if (callback) yield call(callback, false)
  }
}

export function* createPromoCode({
  payload,
  callback
}: CreatePromoCodesAction): SagaIterator {
  let success = false
  try {
    const promoCode = yield call(Punchlist.promoCodes.createPromoCode, payload)
    if (promoCode) {
      yield put(promoCodesActions.getPromoCodes({}))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the promo code'))
    if (callback) yield call(callback, false
    )
  }
}

export function* updatePromoCode({
  payload,
  callback
}: UpdatePromoCodesAction): SagaIterator {
  let success = false
  try {
    const promoCode = yield call(Punchlist.promoCodes.updatePromoCode, payload.id as string, [{
      op: 'add',
      value: payload.active,
      path: '/active'
    }])
    if (promoCode) {
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the promo code'))
    if (callback) yield call(callback, false)
  }
}


export default function* saga(): SagaIterator {
  yield takeLatest(promoCodesTypes.FETCH_PROMO_CODES, getPromoCodes)
  yield takeLatest(promoCodesTypes.CREATE_PROMO_CODE, createPromoCode)
  yield takeLatest(promoCodesTypes.UPDATE_PROMO_CODE, updatePromoCode)
}
