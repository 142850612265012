/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from '../../helpers'
import { Punchlist } from '../../api'
import { vendorTypes, FetchVendorAction, UpdateVendorAction, UpdateVendorInsuranceAction, DeleteVendorInsuranceAction, AddVendorListAction, CreateQuickBooksIdAction } from './types'
import { vendorActions } from '../actions'
import { UpdateRequest } from '~api/types'
import { Vendor, Insurance } from '../types'
import { getVendor } from './selectors'

export function* fetchVendor({ payload, callback }: FetchVendorAction): SagaIterator {
  let success = false
  try {
    // const vendor = yield call(Punchlist.vendors.getVendorById, payload)
    // const statistics = yield call(Punchlist.vendors.getVendorStatics, payload)
    // const jobs = yield call(Punchlist.vendors.getVendorJobs, payload)
    const { vendor, statistics, jobs } = yield all({
      vendor: call(Punchlist.vendors.getVendorById, payload),
      statistics: call(Punchlist.vendors.getVendorStatics, payload),
      jobs: call(Punchlist.vendors.getVendorJobs, payload)
    });
    if (vendor) {
      yield put(vendorActions.setVendor(vendor))
      yield put(vendorActions.setVendorValue({ attr: 'statistics', value: statistics }))
      yield put(vendorActions.setVendorValue({ attr: 'jobs', value: jobs }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the vendor'))
    if (callback) yield call(callback, false)
  }
}

export function* createQuickBooksId({ payload, callback }: CreateQuickBooksIdAction): SagaIterator {
  let success = false
  try {
    // const vendor = yield call(Punchlist.vendors.getVendorById, payload)
    // const statistics = yield call(Punchlist.vendors.getVendorStatics, payload)
    // const jobs = yield call(Punchlist.vendors.getVendorJobs, payload)
    const vendor = yield call(Punchlist.vendors.createQbId, payload);

    if (vendor) {
      yield put(vendorActions.setVendor(vendor))
    }

    success = true

    if (callback) yield call(callback, success)

  } catch (error) {
    
    yield call(toast.error, errorTextTryingTo('generate quickbooks id'))
    
    if (callback) yield call(callback, false)
  }
}

export function* updateVendor({ payload, callback }: UpdateVendorAction): SagaIterator {
  let success = false
  const vendor = yield select(getVendor())

  try {
    const request: UpdateRequest = []

    for (const attr in payload) {
      request.push({
        op: 'replace',
        path: '/' + attr,
        value: payload[attr as keyof Vendor]
      })
    }
    const vendorResponse = yield call(Punchlist.vendors.updateVendor, vendor.id, request)

    if (!isEmpty(vendorResponse)) {
      for (const attr in payload) {
        yield put(vendorActions.setVendorValue({ attr: attr as keyof Vendor, value: payload[attr as keyof Vendor] }))
      }
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the vendor'))
    if (callback) yield call(callback, false)
  }
}

export function* addVendorList({ payload, list, callback }: AddVendorListAction): SagaIterator {
  let success = false
  const vendor = yield select(getVendor())
  try {
    const request: UpdateRequest = [{
      op: 'add',
      path: '/' + list + '/-',
      value: payload
    }]

    const vendorResponse = yield call(Punchlist.vendors.updateVendor, vendor.id, request)

    if (!isEmpty(vendorResponse)) {
      yield put(vendorActions.setVendorValue({ attr: list as keyof Vendor, value: vendorResponse[list] }))
      if (callback) yield call(callback, success)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the vendor'))
    if (callback) yield call(callback, false)
  }
}

export function* updateVendorList({ payload, id, list, callback }: UpdateVendorInsuranceAction): SagaIterator {
  let success = false
  const vendor = yield select(getVendor())

  try {
    const request: UpdateRequest = []

    for (const attr in payload) {
      request.push({
        op: 'replace',
        path: `/${list}/${id}/${attr}`,
        value: payload[attr as keyof Insurance]
      })
    }
    const vendorResponse = yield call(Punchlist.vendors.updateVendor, vendor.id, request)

    if (!isEmpty(vendorResponse)) {
      const newvendor = yield call(Punchlist.vendors.getVendorById, vendor.id)
      yield put(vendorActions.setVendor(newvendor))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the order history'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteVendorInsuranceList({ payload, id, isInsurance, callback }: DeleteVendorInsuranceAction): SagaIterator {
  let success = false
  const vendor = yield select(getVendor())

  try {
    const request: UpdateRequest = []
    if (isInsurance) {
      request.push({
        op: 'remove',
        path: `/insuranceList/${id}`
      })
      request.push({
        op: 'add',
        path: '/licenseList/-',
        value: payload
      })
    } else {
      request.push({
        op: 'remove',
        path: `/licenseList/${id}`
      })
      request.push({
        op: 'add',
        path: '/insuranceList/-',
        value: payload
      })
    }

    const vendorResponse = yield call(Punchlist.vendors.updateVendor, vendor.id, request)

    if (!isEmpty(vendorResponse)) {
      const newvendor = yield call(Punchlist.vendors.getVendorById, vendor.id)
      yield put(vendorActions.setVendor(newvendor))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the order history'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(vendorTypes.FETCH_VENDOR, fetchVendor)
  yield takeLatest(vendorTypes.CREATE_QBID, createQuickBooksId)
  yield takeLatest(vendorTypes.UPDATE_VENDOR, updateVendor)
  yield takeLatest(vendorTypes.UPDATE_VENDOR_INSURANCE, updateVendorList)
  yield takeLatest(vendorTypes.ADD_VENDOR_LIST, addVendorList)
  yield takeLatest(vendorTypes.DELETE_VENDOR_INSURANCE, deleteVendorInsuranceList)
}
