import { Image, Page, Text, View } from '@react-pdf/renderer'
import styles from './style'
import { logoHouseWhite, logoWhite, pdfBg1 } from 'src/assets/index'
import { formatTimestamp } from 'src/helpers/index'

const Page1 = ({ userInfo, qrCode, estimate }) => {
  return (
    <Page size="A4">
      <View style={styles.page1}>
        <View style={styles.pageBg}>
          <Image style={styles.imgBg} src={pdfBg1} alt="bg" />
        </View>
        <View style={styles.page1Content}>
          <View style={styles.page1Top}>
            <Image
              style={{ ...styles.logoSmall, ...styles.mh2 }}
              src={logoHouseWhite}
              alt="bosscat logo small"
            />
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <View stlye={{
                position: 'relative',
              }}>
                <Image
                  style={{ ...styles.logo, ...styles.mh5 }}
                  src={logoWhite}
                  alt="bosscat logo"
                />
                <Text
                  style={{
                    ...styles.white,
                    ...styles.estimateTitle,
                    ...styles.mb4,
                    ...styles.fontXXXLarge
                  }}
                >
                  {userInfo.serviceable ? 'ESTIMATE' : 'PLANNER'}
                </Text>
              </View>
            </View>
            <Text
              style={{
                ...styles.white,
                ...styles.nextArtBold,
                ...styles.fontMedium,
                ...styles.font18,
                ...styles.mh2,
              }}
            >
              REPAIR. RENOVATE. MAINTAIN. ENJOY.
            </Text>
          </View>
          <View
            style={{
              ...styles.mt5, ...styles.row, ...styles.spaceBetween,
            }}>
            <View style={{ ...styles.w70 }}>
              <View style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'flex-start',
                alignItems: 'flex-start',
              }}>
                <Text style={{ ...styles.latoNormal, ...styles.white, marginTop: "8px", ...styles.estimateFlag, ...styles.mh3 }}>
                  {`Estimate #${estimate?.properties?.publicId}`}
                </Text>
              </View>
              <Text
                fixed
                style={{
                  ...styles.white,
                  ...styles.nextArtBold,
                  ...styles.fontSmall,
                  ...styles.mh3,
                }}
              >
                Created on {formatTimestamp(estimate.createdOn, 'MM/DD/YYYY')}
              </Text>
              <Text
                style={{ ...styles.latoBold, ...styles.white, ...styles.mh3, ...styles.mb1 }}
              >
                PREPARED FOR:
              </Text>
              <Text style={{ ...styles.latoBold, ...styles.white }}>
                {userInfo?.name}
              </Text>
              <Text style={{ ...styles.latoBold, ...styles.white }}>
                {userInfo?.address?.line_1}
              </Text>
              <Text style={{ ...styles.latoBold, ...styles.white }}>
                {userInfo?.address?.line_2}
              </Text>
              <Text style={{ ...styles.latoBold, ...styles.white }}>
                {`${userInfo?.address?.city}, ${userInfo?.address?.state} ${userInfo?.address?.zipCode}`}
              </Text>
            </View>
            <View style={styles.qrContainer}>
              <Text
                style={{
                  ...styles.white,
                  ...styles.fontSmall,
                  ...styles.latoBold,
                }}
              >
                Your Interactive
              </Text>
              <Text
                style={{
                  ...styles.white,
                  ...styles.fontSmall,
                  ...styles.latoBold,
                }}
              >
                Online Estimate
              </Text>
              {qrCode && (
                <Image
                  src={qrCode}
                  alt="estimate qr code"
                  style={{
                    ...styles.mh2,
                    ...styles.qrCode,
                  }}
                />
              )}
            </View>
          </View>
          {!userInfo.serviceable && <Text style={{
            ...styles.white,
            ...styles.fontSmall,
            ...styles.latoBold,
          }}>
            {`\n\nThis Estimate is for informational purposes ONLY.  BOSSCAT Home services hasn’t quite\nexpanded to your area at this time and will not be able to complete this project.  We will let you know as soon as our coverage expands into your area.`}
          </Text>}
        </View>
      </View>
      <View
        style={{
          ...styles.contactBanner,
          ...styles.flexColumn,
          ...styles.alignCenter,
          ...styles.fontMedium,
        }}
      >
        <Text
          style={{
            ...styles.white,
            ...styles.latoBold,
            ...styles.textCenter,
            ...styles.font20
          }}
        >
          CONTACT US
        </Text>
        <Text
          style={{
            ...styles.white,
            ...styles.textCenter,
            ...styles.latoNormal,
            ...styles.font20,
          }}
        >
          CustomerCare@BosscatHome.com
        </Text>
        <Text
          style={{
            ...styles.white,
            ...styles.textCenter,
            ...styles.latoNormal,
            ...styles.font20,
          }}
        >
          1-877-4-BOSSCAT
        </Text>
      </View>
    </Page>
  )
}

export default Page1
