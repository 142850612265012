import { GetPromoCodesParams, PromoCodesActions, promoCodesTypes, PromoCodeType } from "./types"

const actions = {
  getPromoCodes: (
    payload: Partial<GetPromoCodesParams>,
    callback?: (succ: boolean) => void
  ): PromoCodesActions => ({
    type: promoCodesTypes.FETCH_PROMO_CODES,
    payload,
    callback
  }),
  setPromoCodes: (payload: PromoCodeType[]): PromoCodesActions => ({
    type: promoCodesTypes.SET_PROMO_CODES,
    payload
  }),
  updatePromoCode: (payload: Partial<PromoCodeType>, callback?: (succ: boolean) => void): PromoCodesActions => ({
    type: promoCodesTypes.UPDATE_PROMO_CODE,
    payload,
    callback
  }),
  createPromoCode: (payload: Partial<PromoCodeType>, callback?: (succ: boolean) => void): PromoCodesActions => ({
    type: promoCodesTypes.CREATE_PROMO_CODE,
    payload,
    callback
  })
}

export default actions
