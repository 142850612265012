import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/invoices'

const apiController: ApiController = {
  getInvoices: async ({ params }) => {
    const parameters = formatParams(params)
    return await axios
      .get(`${base}?${parameters}`)
      .then((response) => response.data)
  },
  createInvoice: async (request, params) => {
    const parameters = formatParams(params)
    return await axios
      .post(`${base}?${parameters}`, request)
      .then((response) => response.data)
  },
  getInvoiceById: async (id: string) =>
    await axios.get(`${base}/${id}`).then((response) => response.data),

  getInvoiceTotal: async (clientId) => {
    return await axios
      .get(`${base}/totals?${clientId}`)
      .then((response) => response.data)
  },
  /*
  getInvoicePreview: async (jobId) => {
    return await axios
      .get(`${base}/preview?jobId=${jobId}`)
      .then(response => response.data)
  },
*/
  getInvoicePreviewPathFile: async ({
    jobId,
    showImages,
    showPricing,
    showRepairNotes,
    showPreliminaryItems = false,
  }) => {
    return await axios
      .post(`${base}/pdf?jobId=${jobId}&showImages=${showImages}&showPricing=${showPricing}&showRepairNotes=${showRepairNotes}&showPreliminaryItems=${showPreliminaryItems}`) //pdf?jobId=
      //.post(`${base}/pdf?jobId=Zmpj3zWqpdSYEZX3F`)
      .then((response) => response.data)
  },
  getInvoicePreview: async ({ params }) => {
    const parameters = formatParams(params)
    return await axios
      .get(`${base}/preview?${parameters}`)
      .then((response) => response.data)
  },
}

export default apiController
