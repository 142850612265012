import { SearchParams } from '../searches/types'
import { PCViewActions, pcViewTypes, PCViewType } from './types'

const actions = {
  fetchPCPortfolioSummary: (
    payload: {
      id: string
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_PORTFOLIO_SUMMARY,
    payload,
    callback,
  }),

  fetchPCPortfolioCount: (
    payload: {
      id: string
      openOrClosed?: string | null,
      workOrderStatus?: string | null,
      jobStatus?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      start?: number,
      limit?: number
      territory?: string | null,
      territoryManager?: string | null,
      successManager?: string | null,
      affiliate?: string | null,
      affiliateType?: string | null
      paymentStatus?: string | null
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_PORTFOLIO_COUNT,
    payload,
    callback,
  }),

  fetchPCWorkOrders: (
    payload: {
      id: string,
      openOrClosed?: string | null,
      workOrderStatus?: string | null,
      jobStatus?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      start?: number,
      limit?: number
      territory?: string | null,
      territoryManager?: string | null,
      successManager?: string | null,
      affiliate?: string | null,
      affiliateType?: string | null
      paymentStatus?: string | null
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_WORK_ORDERS,
    payload,
    callback,
  }),

  fetchPCDetails: (
    payload: {
      id: string,
      openOrClosed?: string | null,
      workOrderStatus?: string | null,
      jobStatus?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      start?: number,
      limit?: number
      territory?: string | null,
      territoryManager?: string | null,
      successManager?: string | null,
      affiliate?: string | null,
      affiliateType?: string | null
      paymentStatus?: string | null
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_DETAILS,
    payload,
    callback,
  }),

  fetchPCFinancials: (
    payload: {
      id: string,
      openOrClosed?: string | null,
      workOrderStatus?: string | null,
      jobStatus?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      start?: number,
      limit?: number
      territory?: string | null,
      territoryManager?: string | null,
      successManager?: string | null,
      affiliate?: string | null,
      affiliateType?: string | null
      paymentStatus?: string | null
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_FINANCIALS,
    payload,
    callback,
  }),

  fetchPCFinancialsJobs: (
    payload: {
      id: string,
      openOrClosed?: string | null,
      workOrderStatus?: string | null,
      jobStatus?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      start?: number,
      limit?: number
      territory?: string | null,
      territoryManager?: string | null,
      successManager?: string | null,
      affiliate?: string | null,
      affiliateType?: string | null
      paymentStatus?: string | null
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_FINANCIALS_JOBS,
    payload,
    callback,
  }),

  setPCView: (payload: PCViewType): PCViewActions => ({
    type: pcViewTypes.SET_PC_VIEW_ACTION,
    payload,
  }),
  downloadCSV: (
    payload: {
      id: string,
      searchParams?: Partial<SearchParams>
    }, callback: (succ: boolean) => void): PCViewActions => ({
      type: pcViewTypes.DOWNLOAD_PC_CSV,
      payload,
      callback,
    }),
  getCSV: (
    payload: string,
    callback: (succ: boolean) => void): PCViewActions => ({
      type: pcViewTypes.GET_PC_CSV,
      payload,
      callback,
    }),
  setCSVPC: (payload: string, callback: (succ: boolean) => void): PCViewActions => ({
    type: pcViewTypes.SET_PC_CSV,
    payload,
    callback
  })
}

export default actions
