/** TYPES **/
export const promoCodesTypes = {
  FETCH_PROMO_CODES: 'FETCH_PROMO_CODES',
  SET_PROMO_CODES: 'SET_PROMO_CODES',
  UPDATE_PROMO_CODE: 'UPDATE_PROMO_CODE',
  CREATE_PROMO_CODE: 'CREATE_PROMO_CODE',
}

export type PromoCodeType = {
  id: string;
  createdBy: string;
  createdOn: number;
  lastUpdate: number;
  startDate: number;
  deliveryDays: number;
  title: string;
  maxUses: number;
  amount: number;
  expirationDate: number;
  promotionType: "FIXED" | "PERCENTAGE";
  resourceType: "ESTIMATE" | "SUBSCRIPTION"
  active: boolean;
  code: string;
  percentOff: number;
}

export interface GetPromoCodesParams {
  // startDate: string
  // endDate: string
  // territory: string
  // jobId: string
  // status: string
  // page?: number
  // size?: number
  // affiliate?: string
}

/** ACTIONS **/

export interface GetPromoCodesAction {
  type: typeof promoCodesTypes.FETCH_PROMO_CODES
  payload: Partial<GetPromoCodesParams>
  // start?: number,
  // limit?: number,
  callback?: (succ: boolean) => void
}

export interface SetPromoCodesAction {
  type: typeof promoCodesTypes.SET_PROMO_CODES
  payload: PromoCodesType
}
export interface UpdatePromoCodesAction {
  type: typeof promoCodesTypes.UPDATE_PROMO_CODE
  payload: Partial<PromoCodeType>
  callback?: (succ: boolean) => void
}

export interface CreatePromoCodesAction {
  type: typeof promoCodesTypes.CREATE_PROMO_CODE
  payload: Partial<PromoCodeType>
  callback?: (succ: boolean) => void
}


export type PromoCodesActions =
  | GetPromoCodesAction
  | SetPromoCodesAction
  | UpdatePromoCodesAction
  | CreatePromoCodesAction

/** REDUCER **/
export type PromoCodesType = {
  start: number
  limit: number
  total: number
  items: PromoCodeType[]
}
