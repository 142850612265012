import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/promos'

const apiController: ApiController = {
  getPromoCodes: async ({
    params
  }) => {
    const parameters = formatParams(params)
    return await axios
      .get(`${base}?${parameters}`, {
        params: {
          // start,
          // limit
        }
      })
      .then(response => response.data)
  },

  createPromoCode: async (request) => {
    return await axios
      .post(`${base}`, request)
      .then(response => response.data)
  },

  updatePromoCode: async (promoCodeId: string, request) =>
    await axios
      .patch(`${base}/${promoCodeId}`, request)
      .then(response => response.data),
}

export default apiController
