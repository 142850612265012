import { FC, useState, useCallback, useEffect, useContext, useRef } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  SelectInput,
  Typography,
} from 'src/components/UI'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useDispatch, useSelector } from 'react-redux'
import {
  employeeIsAdmin,
  getJob,
  getTerritorySuccessManagers,
} from 'src/ducks/selectors'
import {
  getInvoicePreview,
  getInvoicePDFPath,
} from 'src/ducks/invoice/selectors'
import {
  jobActions,
  materialInvoicesActions,
  invoiceActions,
  itemsActions,
} from 'src/ducks/actions'
import { toast } from 'react-toastify'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import styles from './styles.module.scss'
import { ControlButtonsProp, AcOption } from './types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PAYMENT_STATUS, PAYMENT_TYPE, STATUS } from 'src/helpers'
import Modals from './Modals'
import { JobModalTypes } from './Modals/types'
import TextDivider from 'src/components/UI/CustomUI/atoms/TextDivider'
import { CheckListProps } from './types'
import { optionInfo } from './constants'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { PreviewInvoiceContext } from '../../PreviewInvoice/context'
import InvoiceOptionModal from '../../PreviewInvoice/InvoicePrice/InvoiceOptionModal'
import { CSVLink } from 'react-csv'
import ExportCSV from 'src/components/UI/CustomUI/molecules/ExportCSV'


const ControlButtons: FC<ControlButtonsProp> = ({ refresh }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const job = useSelector(getJob())
  const territorySuccessManagers = useSelector(getTerritorySuccessManagers)
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()

  const orderId = searchParams.get('order')

  const [open, setOpen] = useState(false)
  const [modalType, setModalType] = useState<JobModalTypes>()

  const [itemsCSV, setItemsCSV] = useState<string>()
  const isAdmin = useSelector(employeeIsAdmin)

  const [openModalPDF, setopenModalPDF] = useState(false)
  const [openInvoiceOptionModal, setOpenInvoiceOptionModal] = useState(false)
  const [openPreliminaryInvoiceOptionModal, setOpenPreliminaryInvoiceOptionModal] = useState(false)

  const { state: { optionSelectedList } } = useContext(PreviewInvoiceContext)
  const [selectedList, setSelectedList] = useState<boolean[]>(
    optionSelectedList ?? []
  )

  const downloadRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const fetchInvoicePreview = useCallback(() => {
    if (job?.id) {

      dispatch(invoiceActions.getInvoicePreview({ params: { jobId: job?.id } },
        (_succ: boolean) => {

        }))
    }
  }, [])

  useEffect(() => {
    //fetchInvoicePreview()
  }, [])

  const OPTION_ACTIONS = [
    {
      key: 'WO',
      label: 'Work Order',
      isDivider: true
    },
    {
      key: 'RCO',
      label: 'Request Change Order Approval'
    },
    {
      key: 'IV',
      label: 'Invoicing',
      isDivider: true
    },
    {
      key: 'DI',
      label: 'Send Deposit Invoice',
    },
    {
      key: 'DWNI',
      label: 'Download Invoice PDF'
    },
    {
      key: 'DWPNI',
      label: 'Download Preliminary Invoice'
    },
    {
      key: 'MA',
      label: 'Materials',
      isDivider: true
    },
    {
      key: 'MI',
      label: 'Create Material Invoice'
    },
    {
      key: 'CE',
      label: 'Estimate',
      isDivider: true
    },
    {
      key: 'VCE',
      label: 'View Customer Estimate',
    },
    {
      key: 'Items',
      label: 'Items',
      isDivider: true
    },
    {
      key: 'Export to CSV',
      label: 'Export to CSV',
    },
  ]

  const ADMIN_ACTIONS = [
    {
      key: 'AR',
      label: job?.properties.archived ? 'Unarchive' : 'Archive'
    },
    {
      key: 'ER',
      label: job?.properties.excludedFromReports ? 'Include in Report' : 'Exclude from Reports'
    }
  ]

  if (job?.vendorPaid === 0) {
    ADMIN_ACTIONS.push({
      key: 'CWO',
      label: 'Clear Approval'
    })
  }

  if (job?.status === STATUS.COMPLETE || job?.status === STATUS.CLOSED) {

    const index = OPTION_ACTIONS.findIndex((option) => option.key === 'DI')
    OPTION_ACTIONS.splice(index, 1)

    ADMIN_ACTIONS.push({
      key: 'reopen_job',
      label: 'Reopen the job'
    });

    OPTION_ACTIONS.splice(3, 0, {
      key: 'SI',
      label: 'Send Invoice'
    });
  }

  const getInvoiceOptionPayload = () => {
    return optionInfo.reduce(
      (prev, { optionCheck }, index) => ({
        ...prev,
        [optionCheck]: true,
      }),
      {}
    )
  }

  const invoicePDFPath = useSelector(getInvoicePDFPath())


  const fetchMaterialInvoices = useCallback(() => {
    if (job?.id) {
      setLoading(true)
      dispatch(materialInvoicesActions.fetchMaterialInvoices(job?.id,
        (_succ: boolean) => {
          setLoading(false)
          setOpen(true)
          setModalType(JobModalTypes.MATERIAL_INVOICES)
        }))
    }
  }, [job?.id])

  const onRefresh = () => {
    refresh()

    /*  
      if (job) {
        setLoading(true)
        dispatch(jobActions.fetchJob(job.id, () => {
          setLoading(false)
        })) 
      }
   */
  }

  const handleOptions = (newValue: SelectInputOption): void => {
    if (newValue.key === 'ER') {
      setOpen(true)
      setModalType(JobModalTypes.EXCLUDE_FROM_REPORTS)
    }
    if (newValue.key === 'AR') {
      setOpen(true)
      setModalType(JobModalTypes.ARCHIVE_JOB)
    }
    if (newValue.key === 'CWO') {
      setOpen(true)
      setModalType(JobModalTypes.CLEAR_JOB)
    }
    if (newValue.key === 'SI' || newValue.key === 'VI') { navigate(`/invoice/jobId/${job?.id}/preview`) }
    if (newValue.key === 'reopen_job') {
      setOpen(true)
      setModalType(JobModalTypes.REOPEN_JOB)
    }

    if (newValue.key === 'MI') {
      fetchMaterialInvoices()
      //fetchMaterialInvoicesNuevaFunction()
    }

    if (newValue.key === 'DI') { navigate(`/invoice/jobId/${job?.id}/deposit`) }

    if (newValue.key === 'RCO') {
      setOpen(true)
      setModalType(JobModalTypes.REQUEST_CHANGE_ORDER_APPROVAL)
    }

    if (newValue.key === 'DWNI') {
      openPdfInNewTab()
    }
    if (newValue.key === 'DWPNI') {
      openPreliminaryInvoicePdfInNewTab()
    }

    if (newValue.key === 'VCE') {
      window.open(`/estimates/${job?.id}/view-estimate`, '_blank')
    }

    if (newValue.key === 'Export to CSV') {
      dispatch(
        itemsActions.downloadItemsCSV(
          { id: job?.id || '', type: 'jobs', orderId: orderId || '' },
          (succ: boolean, items: any) => {
            setItemsCSV(items)
            downloadRef.current?.link.click()
          }
        )
      )
    }
  }

  const handleAssigned = (newValue: SelectInputOption): void => {
    setLoading(true)
    setOpen(false)
    dispatch(jobActions.updateJob({
      successManagerId: newValue.key
    }, () => {
      setLoading(false)
      setOpen(false)
      toast.success.call('success', 'Job Assigned to: ' + newValue.label)
    }))
    setOpen(false)
    setLoading(false)
  }

  const fetchInvoicePathFile = (
    optionCheckList: CheckListProps = getInvoiceOptionPayload()
  ) => {
    if (job?.id) {

      /*
      dispatchContext({
        type: previewInvoiceTypes.SET_VALUE,
        payload: { attr: 'pageLoading', value: true },
      })
      */
      //setLoading(true)
      setopenModalPDF(true)
      dispatch(
        invoiceActions.getInvoicePreviewPathFile(
          { jobId: job?.id, ...optionCheckList },
          (_succ: boolean, pathFile: string) => {

            if (pathFile) {
              const link = document.createElement('a');
              link.href = pathFile;
              link.setAttribute('download', 'image.jpg');
              document.body.appendChild(link);
              link.click();
              // window.open(pathFile, '_blank', 'noreferrer')
            }

            setopenModalPDF(false)
            setOpenInvoiceOptionModal(false)
          }
        )
      )


    }
  }
  const fetchPreliminaryInvoicePathFile = (
    optionCheckList: CheckListProps = getInvoiceOptionPayload()
  ) => {
    if (job?.id) {

      /*
      dispatchContext({
        type: previewInvoiceTypes.SET_VALUE,
        payload: { attr: 'pageLoading', value: true },
      })
      */
      //setLoading(true)
      setopenModalPDF(true)
      dispatch(
        invoiceActions.getInvoicePreviewPathFile(
          { jobId: job?.id, ...optionCheckList, showPreliminaryItems: true },
          (_succ: boolean, pathFile: string) => {

            if (pathFile) {
              const link = document.createElement('a');
              link.href = pathFile;
              link.setAttribute('download', 'image.jpg');
              document.body.appendChild(link);
              link.click();
              // window.open(pathFile, '_blank', 'noreferrer')
            }

            setopenModalPDF(false)
            setOpenInvoiceOptionModal(false)
          }
        )
      )


    }
  }


  const openPdfInNewTab = () => {
    setOpenInvoiceOptionModal(true)
  }
  const openPreliminaryInvoicePdfInNewTab = () => {
    setOpenPreliminaryInvoiceOptionModal(true)
  }

  return (
    <Box>
      {itemsCSV && (
        <ExportCSV
          headers={itemsCSV
            ?.slice(0, itemsCSV?.indexOf('\n'))
            ?.split(',')
            ?.map((h: any) => h)}
          data={itemsCSV?.slice(itemsCSV?.indexOf('\n') + 1)}
          innerRef={downloadRef}
          fileName="items.csv"
          label=""
        />
      )}
      <Grid
        container
        justifyContent='flex-end'
        spacing={1}
        flexWrap="nowrap"
      >
        <Grid item>
          {loading ?
            <CircularProgress />
            :
            <IconButton onClick={onRefresh}>
              <RefreshIcon />
            </IconButton>
          }
        </Grid>
        {/* {job?.readyForCompletion && <Grid item>
          <Button
            fullWidth
            variant="contained"
            sx={{ minWidth: '130px !important' }}
            onClick={() => {
              setOpen(true)
              setModalType(JobModalTypes.COMPLETE_JOB)
            }}
          >Mark Complete</Button>
        </Grid>} */}
        <Grid item width="100%">
          <SelectInput
            onChange={handleAssigned}
            disableClearable
            disabled={loading} value='Assigned'
            options={territorySuccessManagers.map((tsm) => {
              return {
                key: tsm.id,
                label: tsm.firstName + ' ' + tsm.lastName
              }
            })}
            renderOption={(props: any, option: any, { selected }: any) => (
              <li {...props}>
                {job?.successManagerId === option.key &&
                  <CheckCircleIcon className={styles.SuccessManagerOptionsIcon} />}
                {option.label}
              </li>
            )}
            size='medium'
            sx={{ width: '170px' }}
          />
        </Grid>
        <Grid item width="100%">
          <SelectInput
            value='Options'
            disableClearable
            disabled={loading}
            onChange={handleOptions}
            options={OPTION_ACTIONS}
            size='medium'
            sx={{ width: '160px' }}
            paperWidth='201px'
            renderOption={(props: any, { isDivider, label }: AcOption) => (
              <li {...props}>
                {isDivider === true ? <TextDivider label={label} /> : label}
              </li>
            )}
            getOptionDisabled={({ isDivider }: AcOption) => isDivider ?? false}
          />
        </Grid>
        {isAdmin && (
          <Grid item width="100%">
            <SelectInput
              disableClearable
              disabled={loading}
              value='Admin'
              onChange={handleOptions}
              options={ADMIN_ACTIONS}
              sx={{ width: '160px' }}
            />
          </Grid>
        )}
      </Grid>
      <div>
        {open &&
          <Modals
            setOpen={setOpen}
            open={open}
            modalType={modalType}
            setLoading={setLoading}
          >{' '}
          </Modals>
        }
      </div>
      <div>
        {openInvoiceOptionModal &&
          <InvoiceOptionModal
            open={openInvoiceOptionModal}
            setOpen={setOpenInvoiceOptionModal}
            refreshInvoicePDF={fetchInvoicePathFile}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
          />
        }
        {openPreliminaryInvoiceOptionModal &&
          <InvoiceOptionModal
            open={openPreliminaryInvoiceOptionModal}
            setOpen={setOpenPreliminaryInvoiceOptionModal}
            refreshInvoicePDF={fetchPreliminaryInvoicePathFile}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
          />
        }
      </div>
      <div>
        {openModalPDF &&
          <Modal
            setOpen={() => { }}
            open={openModalPDF}
            title={<Grid container
              spacing={1}
              padding='8px'
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="center"> <Grid item>
                Downloading the invoice
              </Grid> </Grid>}
          >
            <Grid
              container
              spacing={3}
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Typography>PLEASE WAIT… jobs with many images take time. The system is working.</Typography>
              </Grid>
              <Grid item xs={12} container justifyContent='center'>
                <CircularProgress />
              </Grid>
            </Grid>
          </Modal>}
      </div>
    </Box >
  )
}

export default ControlButtons
