import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const Spinner = ({ size = 50, color = "#000" }) => {
    const spinnerStyle = {
        width: size,
        height: size,
        border: `${size / 10}px solid ${color}`,
        borderTop: `${size / 10}px solid transparent`,
    };

    return <div className={styles.spinner} style={spinnerStyle}></div>;
};

Spinner.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

export default Spinner;
